import React, { useState } from "react";
import { useGLTF, MeshTransmissionMaterial } from "@react-three/drei";
import {
  MeshPhysicalMaterial,
  MeshStandardMaterial,
  TextureLoader,
} from "three";

let mirrorGlobalMaterial;

// export function BaseModel(props) {
//   const { nodes, materials } = useGLTF("models/BaseModel-transformed.glb");
//   return (
//     <>
//       <SoftShadows />
//       <group {...props} dispose={null}>
//         <mesh
//           geometry={nodes.BathroomBase_Ceiling.geometry}
//           material={materials.StuccoWalls}
//         />
//         <mesh
//           geometry={nodes.BathroomBase_MainBuildingWalls.geometry}
//           material={materials.StuccoWalls}
//         />
//         <mesh
//           geometry={nodes.SPA_Extra_toothbrushHolder_GEO.geometry}
//           material={materials.CERAMIC}
//         />
//         <mesh
//           geometry={nodes.ExteriorViewFake.geometry}
//           material={materials.FakeExterior_view}
//         />
//         <mesh
//           geometry={nodes.Door_BaseNew.geometry}
//           material={materials.WoodMDFWhite}
//         />
//         <mesh
//           geometry={nodes.BathroomBase_CabinetWalls001.geometry}
//           material={materials.StuccoWalls}
//         />
//         <mesh geometry={nodes.Mesh026.geometry} material={materials.CERAMIC} />
//         <mesh
//           geometry={nodes.Mesh026_1.geometry}
//           material={materials.aluminium}
//         />
//       </group>
//     </>
//   );
// }

// useGLTF.preload("models/BaseModel-transformed.glb");

export function BaseModel(props) {
  const { nodes, materials } = useGLTF("models/BaseModel.glb");
  return (
    <group castShadow receiveShadow {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.BathroomBase_Ceiling.geometry}
        material={materials.StuccoWalls}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.BathroomBase_MainBuildingWalls.geometry}
        material={materials.StuccoWalls}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_Extra_toothbrushHolder_GEO.geometry}
        material={materials.CERAMIC}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ExteriorViewFake.geometry}
        material={materials.FakeExterior_view}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_BaseNew.geometry}
        material={materials.WoodMDFWhite}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.BathroomBase_CabinetWalls001.geometry}
        material={materials.StuccoWalls}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh026.geometry}
        material={materials.CERAMIC}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh026_1.geometry}
        material={materials.aluminium}
      />
    </group>
  );
}

useGLTF.preload("models/BaseModel.glb");

// export function Spa(props) {
//   const { nodes, materials } = useGLTF("models/Spa-transformed.glb");
//   const checkedOptions = props.checkedOptions;
//   const [spaCheckedOptions, setSpaCheckedOptions] = useState({
//     // types of tub
//     tub: false,
//     showerPan: false,
//     showerTileBase: true,

//     // types of mirror
//     mirror: false,
//     medicineCabinet: false,

//     // types of light
//     sconces: false,
//     aboveMirror: true,
//     centerFixture: true,

//     // glass door
//     glassDoor: false,

//     // types of faucet
//     primaryFaucet: false,
//     secondaryFaucet: false,
//     wallMountFauct: false,

//     // types of shower
//     primaryShower: false,
//     secondaryShower: false,
//     tertiaryShower: true,

//     // wall tile
//     wallSterlina: false,
//     wallTrendz: true,

//     // floor tile
//     floorSterlina: false,
//     floorTrendz: true,

//     // shower floor
//     showerFloorPebbleTan: true,
//     showerFloorPebbleGelato: false,
//     showerFloorPebbleMedici: false,
//   });
//   const customMaterial = new MeshPhysicalMaterial({
//     color: 0xffffff, // Base color
//     metalness: 0,
//     roughness: 0.393,
//     ior: 1.45,
//     alphaTest: 0.156,
//     specularIntensity: 1,
//     specularColor: 0xffffff,
//     transmission: 0,
//     clearcoat: 0.446,
//     clearcoatRoughness: 0.101,
//     sheen: 0.5,
//     sheenRoughness: 0.261,
//     sheenColor: 0xffffff,
//     // tint: 0x00ff00, // Adding a green tint
//   });
//   return (
//     <>
//       <SoftShadows />
//       <group {...props} dispose={null}>
//         <mesh
//           name="SPA_ShowerPrimaryForTub_Purist"
//           geometry={nodes.SPA_ShowerPrimaryForTub_Purist.geometry}
//           material={materials.aluminium}
//           position={[-1.1, 1.325, -0.69]}
//           rotation={[Math.PI / 2, 0, 0]}
//           scale={0.003}
//           visible={spaCheckedOptions.primaryShower}
//           shadow
//         />

//         <mesh
//           name="SPA_P_FaucetPurist"
//           geometry={nodes.SPA_P_FaucetPurist.geometry}
//           material={materials.aluminium}
//           visible={spaCheckedOptions.primaryFaucet}
//         />
//         <group
//           name="SPA_Setting1-ShowerTub_Primary"
//           position={[-1.115, 0.233, -0.086]}
//           rotation={[0, 1.571, 0]}
//           scale={[0.00252, 0.00252, 0.0023]}
//           visible={spaCheckedOptions.tub}
//         >
//           <mesh
//             name="Mesh152"
//             geometry={nodes.Mesh152.geometry}
//             material={materials.CERAMIC}
//           />
//           <mesh
//             name="Mesh152_1"
//             geometry={nodes.Mesh152_1.geometry}
//             material={materials.aluminium}
//           />
//         </group>
//         <group
//           name="SPA_Upgrade_ShowerPurist-K22178_CP001"
//           position={[-0.968, 1.515, -0.848]}
//           scale={0.025}
//           visible={spaCheckedOptions.secondaryShower}
//         >
//           <mesh
//             name="Mesh104"
//             geometry={nodes.Mesh104.geometry}
//             material={materials.aluminium}
//           />
//           <mesh
//             name="Mesh104_1"
//             geometry={nodes.Mesh104_1.geometry}
//             material={materials["GreyPlastic.002"]}
//           />
//         </group>
//         <group
//           name="SPA_Setting2_Upgrade-Shower_pan_Allure-K-A6030"
//           position={[-1.039, 0.008, -0.114]}
//           scale={0.025}
//           visible={spaCheckedOptions.showerPan}
//         >
//           <mesh
//             name="Mesh058"
//             geometry={nodes.Mesh058.geometry}
//             material={materials.CERAMIC}
//           />
//           <mesh
//             name="Mesh058_1"
//             geometry={nodes.Mesh058_1.geometry}
//             material={materials.aluminium}
//           />
//         </group>
//         <group visible={spaCheckedOptions.tertiaryShower}>
//           <mesh
//             name="Mesh108"
//             geometry={nodes.Mesh108.geometry}
//             material={materials.aluminium}
//           />
//           <mesh
//             name="Mesh108_1"
//             geometry={nodes.Mesh108_1.geometry}
//             material={materials["GreyPlastic.002"]}
//           />
//           <mesh
//             name="Mesh109"
//             geometry={nodes.Mesh109.geometry}
//             material={materials.aluminium}
//           />
//           <mesh
//             name="Mesh109_1"
//             geometry={nodes.Mesh109_1.geometry}
//             material={materials["GreyPlastic.002"]}
//           />
//           <mesh
//             name="SPA_Upgrade_ShowerUpgrade_003"
//             geometry={nodes.SPA_Upgrade_ShowerUpgrade_003.geometry}
//             material={materials.aluminium}
//             visible={spaCheckedOptions.tertiaryShower}
//           />
//         </group>
//         <group visible={spaCheckedOptions.showerTileBase}>
//           <mesh
//             name="Mesh138"
//             geometry={nodes.Mesh138.geometry}
//             material={materials.aluminium}
//           />
//           <mesh
//             name="Mesh138_1"
//             geometry={nodes.Mesh138_1.geometry}
//             material={materials["GreyPlastic.002"]}
//           />
//           <mesh
//             name="Mesh138_2"
//             geometry={nodes.Mesh138_2.geometry}
//             material={materials.WhitePVC}
//           />
//           <mesh
//             name="Mesh138_3"
//             geometry={nodes.Mesh138_3.geometry}
//             material={materials["03 - Default Slot #4"]}
//           />
//           <mesh
//             name="Mesh138_4"
//             geometry={nodes.Mesh138_4.geometry}
//             material={materials["03 - Default Slot #4"]}
//           />
//           <mesh
//             name="Mesh138_5"
//             geometry={nodes.Mesh138_5.geometry}
//             material={materials["03 - Default Slot #4"]}
//           />
//           <mesh
//             name="SPA_ShowerFloor"
//             geometry={nodes.SPA_ShowerFloor.geometry}
//             material={materials.Emser_PebbleVenetian_tan}
//             visible={spaCheckedOptions.showerFloorPebbleTan}
//           />
//           <mesh
//             name="SPA_ShowerFloor_1"
//             geometry={nodes.SPA_ShowerFloor.geometry}
//             material={materials.Emser_PebbleVenetian_gelato}
//             visible={spaCheckedOptions.showerFloorPebbleGelato}
//           />
//           <mesh
//             name="SPA_ShowerFloor_2"
//             geometry={nodes.SPA_ShowerFloor.geometry}
//             material={materials.Emser_PebbleVenetian_medici}
//             visible={spaCheckedOptions.showerFloorPebbleMedici}
//           />
//         </group>
//         <mesh
//           visible={spaCheckedOptions.wallMountFauct}
//           name="SPA_Upgrade_FaucetPurist-T14414-4"
//           geometry={nodes["SPA_Upgrade_FaucetPurist-T14414-4"].geometry}
//           material={materials.aluminium}
//         />
//         <group visible={spaCheckedOptions.glassDoor}>
//           <mesh
//             name="702430_L001"
//             geometry={nodes["702430_L001"].geometry}
//             material={materials.aluminium}
//           />
//           <mesh
//             name="702430_L001_1"
//             geometry={nodes["702430_L001_1"].geometry}
//             material={materials.ShowerGlassMain}
//           />
//           <mesh
//             name="702430_L001_2"
//             geometry={nodes["702430_L001_2"].geometry}
//             material={materials["WhitePVC.001"]}
//           />
//         </group>
//         <mesh
//           name="SPA_Upgrade_OptionFaucet_14404M_4A"
//           geometry={nodes.SPA_Upgrade_OptionFaucet_14404M_4A.geometry}
//           material={materials.aluminium}
//           visible={spaCheckedOptions.secondaryFaucet}
//         />
//         <mesh
//           name="SPA_Towels2"
//           geometry={nodes.SPA_Towels2.geometry}
//           material={materials.Cloth}
//           receiveShadow
//           castShadow
//         />
//         <mesh
//           name="SPA_Towels1"
//           geometry={nodes.SPA_Towels1.geometry}
//           material={materials.Cloth}
//         />
//         <mesh
//           name="SPA_TowelHook"
//           geometry={nodes.SPA_TowelHook.geometry}
//           material={materials.aluminium}
//         />
//         <mesh
//           name="SPA_CabinetWood"
//           geometry={nodes.SPA_CabinetWood.geometry}
//           material={materials["WoodVeneer.001"]}
//         />
//         <mesh
//           name="SPA_BathroomCabinetHandles"
//           geometry={nodes.SPA_BathroomCabinetHandles.geometry}
//           material={materials.aluminium}
//         />
//         <mesh
//           name="SPA_BathroomCabinets"
//           geometry={nodes.SPA_BathroomCabinets.geometry}
//           material={materials.Wood_Light}
//         />
//         <mesh
//           name="SPA_BathroomCabinetMarble"
//           geometry={nodes.SPA_BathroomCabinetMarble.geometry}
//           material={materials.Marble}
//         />
//         <mesh
//           name="SPA_A_ToilerRollHolder_14377"
//           geometry={nodes.SPA_A_ToilerRollHolder_14377.geometry}
//           material={materials.aluminium}
//         />
//         <mesh
//           name="SPA_WallTilesType1"
//           geometry={nodes.SPA_WallTilesType1.geometry}
//           material={materials.EmserTrendz_Sterlina}
//           visible={spaCheckedOptions.wallSterlina}
//         />
//         <mesh
//           name="SPA_WallTilesType1_1"
//           geometry={nodes.SPA_WallTilesType1.geometry}
//           material={materials.Emser_TrendzWhite}
//           visible={spaCheckedOptions.wallTrendz}
//         />
//         <mesh
//           name="SPA_WallGainswoodShowershield"
//           geometry={nodes.SPA_WallGainswoodShowershield.geometry}
//           material={materials["Gainswood_pineElmHickory-RGBcontrol"]}
//         />
//         <mesh
//           name="SPA_AdditionalGroutdetail"
//           geometry={nodes.SPA_AdditionalGroutdetail.geometry}
//           material={materials.CERAMIC}
//         />
//         <mesh
//           name="SPA_CeilingLight_litfad"
//           geometry={nodes.SPA_CeilingLight_litfad.geometry}
//           material={customMaterial}
//           visible={spaCheckedOptions.centerFixture}
//         />
//         <mesh
//           name="SPA_shelves_veneer"
//           geometry={nodes.SPA_shelves_veneer.geometry}
//           material={materials["WoodVeneer.001"]}
//         />
//         <mesh
//           name="SPA_WickerBasket1"
//           geometry={nodes.SPA_WickerBasket1.geometry}
//           material={materials.Wicker}
//         />
//         <mesh
//           name="SPA_WickerBasket2"
//           geometry={nodes.SPA_WickerBasket2.geometry}
//           material={materials.Wicker}
//         />
//         <mesh
//           name="SPA_Towerbar_Purist"
//           geometry={nodes.SPA_Towerbar_Purist.geometry}
//           material={materials.aluminium}
//         />
//         <mesh
//           name="SPA_TowelRing_Purist"
//           geometry={nodes.SPA_TowelRing_Purist.geometry}
//           material={materials.aluminium}
//         />
//         <mesh
//           name="SPA_FakeLightFullEmissive"
//           geometry={nodes.SPA_FakeLightFullEmissive.geometry}
//           material={materials.Emissive}
//           visible={spaCheckedOptions.centerFixture}
//         />
//         <mesh
//           name="SPA_DoorHandle"
//           geometry={nodes.SPA_DoorHandle.geometry}
//           material={materials["aluminium.002"]}
//         />
//         <mesh
//           name="SPA_Cabinetwall2"
//           geometry={nodes.SPA_Cabinetwall2.geometry}
//           material={materials["Stucco.001"]}
//         />
//         <mesh
//           name="BathroomBase_Extra_Toilet_PaperRoll001"
//           geometry={nodes.BathroomBase_Extra_Toilet_PaperRoll001.geometry}
//           material={materials["Toilet paper"]}
//         />
//         <mesh
//           name="SPA_Upgrade_ShowerUpgrade_001"
//           geometry={nodes.SPA_Upgrade_ShowerUpgrade_001.geometry}
//           material={materials.aluminium}
//           position={[-1.103, 1.066, -0.846]}
//           scale={0.025}
//           visible={false}
//         />
//         <mesh
//           name="Mesh004"
//           geometry={nodes.Mesh004.geometry}
//           material={materials["WoodVeneer.001"]}
//         />
//         <mesh
//           name="Mesh004_1"
//           geometry={nodes.Mesh004_1.geometry}
//           material={materials.BuddhaImage}
//         />
//         <mesh
//           name="Mesh014"
//           geometry={nodes.Mesh014.geometry}
//           material={materials.aluminium}
//         />
//         <mesh
//           name="Mesh014_1"
//           geometry={nodes.Mesh014_1.geometry}
//           material={materials.CERAMIC}
//         />
//         <mesh
//           name="Mesh031"
//           geometry={nodes.Mesh031.geometry}
//           material={materials.ShampooTexture}
//         />
//         <mesh
//           name="Mesh031_1"
//           geometry={nodes.Mesh031_1.geometry}
//           material={materials.BasicBlackPlastic}
//         />
//         <mesh
//           name="Mesh032"
//           geometry={nodes.Mesh032.geometry}
//           material={materials.BuddhaImage}
//         />
//         <mesh
//           name="Mesh032_1"
//           geometry={nodes.Mesh032_1.geometry}
//           material={materials.ShampooTexture}
//         />
//         <mesh
//           name="Mesh032_2"
//           geometry={nodes.Mesh032_2.geometry}
//           material={materials.BasicBlackPlastic}
//         />
//         <mesh
//           name="Mesh033"
//           geometry={nodes.Mesh033.geometry}
//           material={materials.LeafRealistic}
//         />
//         <mesh
//           name="Mesh033_1"
//           geometry={nodes.Mesh033_1.geometry}
//           material={materials["MarbleType2 "]}
//         />
//         <mesh
//           name="Mesh033_2"
//           geometry={nodes.Mesh033_2.geometry}
//           material={materials.Soil}
//         />
//         <mesh
//           name="Mesh033_3"
//           geometry={nodes.Mesh033_3.geometry}
//           material={materials.aluminium}
//         />
//         <group visible={spaCheckedOptions.aboveMirror}>
//           <mesh
//             name="Mesh035"
//             geometry={nodes.Mesh035.geometry}
//             material={materials.aluminium}
//           />
//           <mesh
//             name="Mesh035_1"
//             geometry={nodes.Mesh035_1.geometry}
//             material={materials.SelfIllumin}
//           />
//         </group>
//         <group visible={!checkedOptions.medicineCabinet}>
//           <mesh
//             name="Mirror"
//             geometry={nodes.Mirror.geometry}
//             material={materials.aluminium}
//           />
//           <mesh
//             name="Mirror_1"
//             geometry={nodes.Mirror_1.geometry}
//             material={materials.Mirror}
//           />
//         </group>
//         <mesh
//           name="Mesh037"
//           geometry={nodes.Mesh037.geometry}
//           material={materials.Glass}
//         />
//         <mesh
//           name="Mesh037_1"
//           geometry={nodes.Mesh037_1.geometry}
//           material={materials.ShampooTexture}
//         />
//         <mesh
//           name="Mesh037_2"
//           geometry={nodes.Mesh037_2.geometry}
//           material={materials.BasicBlackPlastic}
//         />
//         <mesh
//           name="Mesh038"
//           geometry={nodes.Mesh038.geometry}
//           material={materials.Glass}
//         />
//         <mesh
//           name="Mesh038_1"
//           geometry={nodes.Mesh038_1.geometry}
//           material={materials.ShampooTexture}
//         />
//         <mesh
//           name="Mesh038_2"
//           geometry={nodes.Mesh038_2.geometry}
//           material={materials.BasicBlackPlastic}
//         />
//         <mesh
//           name="Mesh045"
//           geometry={nodes.Mesh045.geometry}
//           material={materials["WhitePVC.001"]}
//         />
//         <mesh name="Mesh045_1" geometry={nodes.Mesh045_1.geometry}>
//           <MeshTransmissionMaterial />
//         </mesh>
//         <mesh
//           name="Mesh045_2"
//           geometry={nodes.Mesh045_2.geometry}
//           material={materials["Stucco.001"]}
//         />
//         <mesh
//           name="Mesh045_3"
//           geometry={nodes.Mesh045_3.geometry}
//           material={materials["WoodVeneer.001"]}
//         />
//         <mesh
//           name="Mesh028"
//           geometry={nodes.Mesh028.geometry}
//           material={materials.aluminium}
//         />
//         <mesh
//           name="Mesh028_1"
//           geometry={nodes.Mesh028_1.geometry}
//           material={materials["WhitePVC.001"]}
//         />
//         <mesh
//           name="Mesh007"
//           geometry={nodes.Mesh007.geometry}
//           material={materials["Gainswood_pineElmHickory-RGBcontrol"]}
//         />
//         <mesh
//           name="Mesh007_1"
//           geometry={nodes.Mesh007_1.geometry}
//           material={materials.Emser_PebbleMedici}
//         />
//         <mesh
//           name="Mesh013"
//           geometry={nodes.Mesh013.geometry}
//           material={materials.CERAMIC}
//         />
//         <mesh
//           name="Mesh013_1"
//           geometry={nodes.Mesh013_1.geometry}
//           material={materials.aluminium}
//           visible={false}
//         />
//         <group visible={spaCheckedOptions.medicineCabinet}>
//           <mesh
//             name="A_-_Medicine_cabinet_-_Catalan_-_K-2939-PG-SAA"
//             geometry={
//               nodes["A_-_Medicine_cabinet_-_Catalan_-_K-2939-PG-SAA"].geometry
//             }
//             material={materials.WoodMDFWhite}
//           />
//           <mesh
//             name="A_-_Medicine_cabinet_-_Catalan_-_K-2939-PG-SAA_1"
//             geometry={
//               nodes["A_-_Medicine_cabinet_-_Catalan_-_K-2939-PG-SAA_1"].geometry
//             }
//             material={materials.Mirror}
//           />
//         </group>
//         <group visible={spaCheckedOptions.sconces}>
//           <mesh
//             name="Mesh128"
//             geometry={nodes.Mesh128.geometry}
//             material={materials.aluminium}
//           />
//           <mesh
//             name="Mesh128_1"
//             geometry={nodes.Mesh128_1.geometry}
//             material={materials.SelfIllumin}
//           />
//           <mesh
//             name="Mesh128_2"
//             geometry={nodes.Mesh128_2.geometry}
//             material={materials.LampIllumination}
//           />
//         </group>
//         <mesh
//           name="Mesh157"
//           geometry={nodes.Mesh157.geometry}
//           material={materials.Emser_PebbleVenetian_tan}
//           visible={false}
//         />
//         <mesh
//           name="Mesh157_1"
//           geometry={nodes.Mesh157_1.geometry}
//           material={materials.Emser_SterlinaWhite}
//           visible={spaCheckedOptions.floorSterlina}
//         />
//         <mesh
//           name="Mesh157_2"
//           geometry={nodes.Mesh157_1.geometry}
//           material={materials.Emser_TrendzWhite}
//           visible={spaCheckedOptions.floorTrendz}
//         />
//       </group>
//     </>
//   );
// }

// useGLTF.preload("models/Spa-transformed.glb");

export function Spa(props) {
  const { nodes, materials } = useGLTF("models/spa.glb");
  const checkedOptions = props.spaCheckedOptions;
  const [spaCheckedOptions, setSpaCheckedOptions] = useState({
    // wood color
    lightOak: true,
    whiteWood: false,

    // types of tub
    tub: false,
    showerPan: false,
    showerTileBase: false,

    // types of mirror
    mirror: false,
    medicineCabinet: false,

    // types of light
    sconces: false,
    aboveMirror: false,
    centerFixture: false,

    // glass door
    glassDoor: false,

    // types of faucet
    primaryFaucet: false,
    secondaryFaucet: false,
    wallMountFauct: false,

    // types of shower
    primaryShower: false,
    secondaryShower: false,
    tertiaryShower: false,

    // wall tile
    wallSterlina: false,
    wallTrendz: false,

    // floor tile
    floorSterlina: false,
    floorTrendz: false,

    // shower floor
    showerFloorPebbleTan: false,
    showerFloorPebbleMedici: true,
  });
  return (
    <group receiveShadow castShadow {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_Towels2.geometry}
        material={materials.Cloth}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_Towels1.geometry}
        material={materials.Cloth}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_TowelHook.geometry}
        material={materials.aluminium}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_CabinetWood.geometry}
        material={materials["WoodVeneer.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_BathroomCabinetHandles.geometry}
        material={materials.aluminium}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_BathroomCabinets.geometry}
        material={materials.Wood_Light}
        visible={spaCheckedOptions.lightOak || checkedOptions.lightOak}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_BathroomCabinets.geometry}
        material={materials.WoodMDFWhite}
        visible={spaCheckedOptions.whiteWood || checkedOptions.whiteWood}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_BathroomCabinetMarble.geometry}
        material={materials.Marble}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_A_ToilerRollHolder_14377.geometry}
        material={materials.aluminium}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_WallTilesType1.geometry}
        material={materials.EmserTrendz_Sterlina}
        visible={spaCheckedOptions.wallSterlina || checkedOptions.wallSterlina}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_WallTilesType1.geometry}
        material={materials.Emser_TrendzWhite}
        visible={spaCheckedOptions.wallTrendz || checkedOptions.wallTrendz}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_WallGainswoodShowershield.geometry}
        material={materials["Gainswood_pineElmHickory-RGBcontrol"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_AdditionalGroutdetail.geometry}
        material={materials.CERAMIC}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_CeilingLight_litfad.geometry}
        material={materials.glass2}
        visible={spaCheckedOptions.centerFixture || checkedOptions.elecpkg1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_shelves_veneer.geometry}
        material={materials["WoodVeneer.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_WickerBasket1.geometry}
        material={materials.Wicker}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_WickerBasket2.geometry}
        material={materials.Wicker}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_P_FaucetPurist.geometry}
        material={materials.aluminium}
        visible={
          (spaCheckedOptions.primaryFaucet || checkedOptions.plumbingPkg1) &&
          !checkedOptions.upgradeWallMount
        }
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_Towerbar_Purist.geometry}
        material={materials.aluminium}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_TowelRing_Purist.geometry}
        material={materials.aluminium}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_FakeLightFullEmissive.geometry}
        material={materials.Emissive}
        visible={spaCheckedOptions.centerFixture || checkedOptions.elecpkg1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_DoorHandle.geometry}
        material={materials["aluminium.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_Cabinetwall2.geometry}
        material={materials.StuccoWalls}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.BathroomBase_Extra_Toilet_PaperRoll001.geometry}
        material={materials["Toilet paper"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_Upgrade_OptionFaucet_14404M_4A.geometry}
        material={materials.aluminium}
        visible={
          (spaCheckedOptions.secondaryFaucet ||
            checkedOptions.plumbingPkg2 ||
            checkedOptions.plumbingPkg3) &&
          !checkedOptions.upgradeWallMount
        }
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["SPA_Upgrade_FaucetPurist-T14414-4"].geometry}
        material={materials.aluminium}
        visible={
          spaCheckedOptions.wallMountFauct || checkedOptions.upgradeWallMount
        }
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_ShowerPrimaryForTub_Purist.geometry}
        material={materials.aluminium}
        position={[-1.1, 1.325, -0.69]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.003}
        visible={
          (spaCheckedOptions.primaryShower || checkedOptions.plumbingPkg1) &&
          !checkedOptions.upgradeHandheld &&
          !checkedOptions.upgradeHandheldOverhead
        }
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_Upgrade_ShowerUpgrade_001.geometry}
        material={materials.aluminium}
        position={[-1.103, 1.066, -0.846]}
        scale={0.025}
        visible={
          (spaCheckedOptions.secondaryShower ||
            checkedOptions.plumbingPkg2 ||
            checkedOptions.upgradeHandheld) &&
          !checkedOptions.upgradeHandheldOverhead
        }
      />
      <group
        receiveShadow
        castShadow
        position={[-0.968, 1.515, -0.848]}
        scale={0.025}
        visible={
          spaCheckedOptions.secondaryShower ||
          checkedOptions.plumbingPkg2 ||
          checkedOptions.upgradeHandheld
        }
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh104.geometry}
          material={materials.aluminium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh104_1.geometry}
          material={materials["GreyPlastic.002"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_Upgrade_ShowerUpgrade_003.geometry}
        material={materials.aluminium}
        visible={
          spaCheckedOptions.tertiaryShower ||
          checkedOptions.plumbingPkg3 ||
          checkedOptions.upgradeHandheldOverhead
        }
      />
      <group
        receiveShadow
        castShadow
        position={[-1.115, 0.233, -0.086]}
        rotation={[0, 1.571, 0]}
        scale={[0.00252, 0.00252, 0.0023]}
        visible={
          (spaCheckedOptions.tub || checkedOptions.plumbingPkg1) &&
          !checkedOptions.convertTubShower
        }
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh152.geometry}
          material={materials.CERAMIC}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh152_1.geometry}
          material={materials.aluminium}
        />
      </group>
      <group
        receiveShadow
        castShadow
        position={[-1.039, 0.008, -0.114]}
        scale={0.025}
        visible={spaCheckedOptions.showerPan || checkedOptions.plumbingPkg2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh058.geometry}
          material={materials.CERAMIC}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh058_1.geometry}
          material={materials.aluminium}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh004.geometry}
        material={materials["WoodVeneer.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh004_1.geometry}
        material={materials.BuddhaImage}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh014.geometry}
        material={materials.aluminium}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh014_1.geometry}
        material={materials.CERAMIC}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh031.geometry}
        material={materials.ShampooTexture}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh031_1.geometry}
        material={materials.BasicBlackPlastic}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh032.geometry}
        material={materials.BuddhaImage}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh032_1.geometry}
        material={materials.ShampooTexture}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh032_2.geometry}
        material={materials.BasicBlackPlastic}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh033.geometry}
        material={materials.LeafRealistic}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh033_1.geometry}
        material={materials["MarbleType2 "]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh033_2.geometry}
        material={materials.Soil}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh033_3.geometry}
        material={materials.aluminium}
      />
      <group
        receiveShadow
        castShadow
        visible={spaCheckedOptions.aboveMirror || checkedOptions.elecpkg1}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh035.geometry}
          material={materials.aluminium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh035_1.geometry}
          material={materials.SelfIllumin}
        />
      </group>
      <group
        receiveShadow
        castShadow
        visible={spaCheckedOptions.mirror || !checkedOptions.medicineCabinet}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mirror.geometry}
          material={materials.aluminium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mirror_1.geometry}
          material={materials.aluminium}
        />
      </group>
      <group receiveShadow castShadow>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh037.geometry}
          material={materials.Glass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh037_1.geometry}
          material={materials.ShampooTexture}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh037_2.geometry}
          material={materials.BasicBlackPlastic}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh038.geometry}
        material={materials.Glass}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh038_1.geometry}
        material={materials.ShampooTexture}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh038_2.geometry}
        material={materials.BasicBlackPlastic}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh045.geometry}
        material={materials["WhitePVC.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh045_1.geometry}
        material={MeshTransmissionMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh045_2.geometry}
        material={materials["Stucco.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh045_3.geometry}
        material={materials["VeneerWood.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh028.geometry}
        material={materials.aluminium}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh028_1.geometry}
        material={materials.PVC}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh007.geometry}
        material={materials["Gainswood_pineElmHickory-RGBcontrol"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh007_1.geometry}
        material={materials.Emser_PebbleMedici}
      />
      <group receiveShadow castShadow>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh013.geometry}
          material={materials.CERAMIC}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh013_1.geometry}
          material={materials.aluminium}
        />
      </group>
      <group
        receiveShadow
        castShadow
        visible={
          spaCheckedOptions.medicineCabinet || checkedOptions.medicineCabinet
        }
      >
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["A_-_Medicine_cabinet_-_Catalan_-_K-2939-PG-SAA"].geometry
          }
          material={materials.WoodMDFWhite}
          visible={spaCheckedOptions.whiteWood || checkedOptions.whiteWood}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["A_-_Medicine_cabinet_-_Catalan_-_K-2939-PG-SAA"].geometry
          }
          material={materials.Wood_Light}
          visible={spaCheckedOptions.lightOak || checkedOptions.lightOak}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["A_-_Medicine_cabinet_-_Catalan_-_K-2939-PG-SAA_1"].geometry
          }
          material={materials.aluminium}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["22178"].geometry}
        material={materials.aluminium}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["22178_1"].geometry}
        material={materials["GreyPlastic.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["22178_2"].geometry}
        material={materials.WhitePVC}
      />
      <group
        receiveShadow
        castShadow
        visible={
          spaCheckedOptions.glassDoor ||
          checkedOptions.plumbingPkg2 ||
          checkedOptions.plumbingPkg3
        }
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["702430_L001"].geometry}
          material={materials.aluminium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["702430_L001_1"].geometry}
          material={materials.ShowerGlassMain}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["702430_L001_2"].geometry}
          material={materials["WhitePVC.002"]}
        />
      </group>
      <group
        receiveShadow
        castShadow
        visible={spaCheckedOptions.sconces || checkedOptions.elecpkg2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh128.geometry}
          material={materials.aluminium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh128_1.geometry}
          material={materials.SelfIllumin}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh128_2.geometry}
          material={materials.LampIllumination}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh157.geometry}
        material={materials.Emser_PebbleVenetian_tan}
        visible={false}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh157_1.geometry}
        material={materials.Emser_SterlinaWhite}
        visible={
          spaCheckedOptions.floorSterlina || checkedOptions.floorSterlina
        }
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh157_1.geometry}
        material={materials.Emser_TrendzWhite}
        visible={spaCheckedOptions.floorTrendz || checkedOptions.floorTrendz}
      />
      <group
        receiveShadow
        castShadow
        visible={
          spaCheckedOptions.tertiaryShower ||
          checkedOptions.plumbingPkg3 ||
          checkedOptions.upgradeHandheldOverhead
        }
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh108.geometry}
          material={materials.aluminium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh108_1.geometry}
          material={materials["GreyPlastic.002"]}
        />
      </group>
      <group
        receiveShadow
        castShadow
        visible={
          spaCheckedOptions.tertiaryShower ||
          checkedOptions.plumbingPkg3 ||
          checkedOptions.upgradeHandheldOverhead
        }
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh109.geometry}
          material={materials.aluminium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh109_1.geometry}
          material={materials["GreyPlastic.002"]}
        />
      </group>
      <group
        receiveShadow
        castShadow
        visible={
          spaCheckedOptions.showerTileBase ||
          checkedOptions.plumbingPkg3 ||
          checkedOptions.convertTubShower
        }
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh138.geometry}
          material={materials.aluminium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh138_1.geometry}
          material={materials["GreyPlastic.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh138_2.geometry}
          material={materials.WhitePVC}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh138_3.geometry}
          material={materials["03 - Default Slot #4"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh138_4.geometry}
          material={materials["03 - Default Slot #5"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh138_5.geometry}
          material={materials["03 - Default Slot #6"]}
        />

        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SPA_ShowerFloor.geometry}
          material={materials.Emser_PebbleVenetian_tan}
          visible={spaCheckedOptions.showerFloorPebbleTan}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SPA_ShowerFloor.geometry}
          material={materials.Emser_PebbleMedici}
          visible={spaCheckedOptions.showerFloorPebbleMedici}
        />
      </group>
    </group>
  );
}

useGLTF.preload("models/spa.glb");

export function Glamoure(props) {
  const { nodes, materials } = useGLTF("models/Glamoure.glb");
  const glamoure = props.glamoure;
  return (
    <group receiveShadow castShadow {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Glam_PlantOptimized001.geometry}
        material={materials.Glam_Plant}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Glam_WallPainting.geometry}
        material={materials.Art}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Glam_UpgradeShowerTowelHook.geometry}
        material={materials.Brass}
        visible={glamoure.robeHook}
      />
      <group receiveShadow castShadow visible={glamoure.toiletPaperHolder}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BathroomBase_Extra_Toilet_PaperRoll002.geometry}
          material={materials["Toilet paper"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Glam_UpgradeToiletPaperHolder.geometry}
          material={materials.Brass}
        />
      </group>
      <group
        receiveShadow
        castShadow
        position={[-1.809, 0, 1.617]}
        scale={0.025}
        visible={glamoure.medicineCabinet}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh057.geometry}
          material={materials.Brass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh057_1.geometry}
          material={mirrorGlobalMaterial}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Glam_Upgrade_faucet_K26433.geometry}
        material={materials.Brass}
        visible={glamoure.lavFaucetDeckMount}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Glamoure_UpgradeDoorHandle.geometry}
        material={materials.Brass}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_Towels1002.geometry}
        material={materials.Cloth}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_Towels2001.geometry}
        material={materials.Cloth}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes[
            "Glam_P_-_Option_Single_handle_deck_mount_lav_faucet_-_K-26430-4"
          ].geometry
        }
        material={materials.Brass}
        position={[0.72, 0.907, -0.773]}
        rotation={[Math.PI, 0, 0]}
        scale={-0.025}
        visible={glamoure.lavFaucet}
      />
      <group
        receiveShadow
        castShadow
        position={[-1.115, 0.233, -0.086]}
        rotation={[0, 1.571, 0]}
        scale={[0.00252, 0.00252, 0.0023]}
        visible={glamoure.tub}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh151.geometry}
          material={materials.CERAMIC}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh151_1.geometry}
          material={materials.aluminium}
        />
      </group>
      <group
        receiveShadow
        castShadow
        position={[-1.039, 0.008, -0.114]}
        scale={0.025}
        visible={false}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh140.geometry}
          material={materials.CERAMIC}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh140_1.geometry}
          material={materials.aluminium}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Glam_AccentTileShowerOnly.geometry}
        material={
          materials["Glam_FloorMSIHarlowShevronBlueSaturation 1.5-Grey0"]
        }
      />
      <group
        receiveShadow
        castShadow
        position={[-1.094, 1.768, -0.842]}
        rotation={[Math.PI, 0, 0]}
        scale={-0.025}
        visible={false}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.TS27404_4005.geometry}
          material={materials.Brass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.TS27404_4005_1.geometry}
          material={materials.GreyPlastic}
        />
      </group>
      <group
        receiveShadow
        castShadow
        position={[-1.094, 1.768, -0.842]}
        rotation={[Math.PI, 0, 0]}
        scale={-0.025}
        visible={false}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.TS27404_4002.geometry}
          material={materials.Brass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.TS27404_4002_1.geometry}
          material={materials.GreyPlastic}
        />
      </group>
      <group
        receiveShadow
        castShadow
        position={[-0.166, 0.857, -1.002]}
        rotation={[Math.PI, 0, 0]}
        scale={-0.025}
        visible={false}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.T26432_4001.geometry}
          material={materials.Brass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.T26432_4001_1.geometry}
          material={materials.GreyPlastic}
        />
      </group>
      <group
        receiveShadow
        castShadow
        position={[-0.167, 0.864, -0.993]}
        rotation={[Math.PI, 0, 0]}
        scale={-0.025}
        visible={false}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.T26432_4.geometry}
          material={materials.Brass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.T26432_4_1.geometry}
          material={materials.GreyPlastic}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Glam_FloorCenterTileOnly.geometry}
        material={
          materials["Glam_FloorMSIHarlowShevronBlueSaturation 1.5-Grey0"]
        }
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh046.geometry}
        material={materials.StuccoWalls}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh046_1.geometry}
        material={MeshTransmissionMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh046_2.geometry}
        material={materials.WhitePlasticPVC}
      />
      <group receiveShadow castShadow visible={glamoure.towelRing}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh047.geometry}
          material={materials.Brass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh047_1.geometry}
          material={materials["TowelTexture.002"]}
        />
      </group>
      <group receiveShadow castShadow visible={glamoure.towelBar}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh048.geometry}
          material={materials.Brass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh048_1.geometry}
          material={materials["TowelTexture.002"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh056.geometry}
        material={materials["Brass_Yellow.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh056_1.geometry}
        material={materials["Self_Illumin_emissive.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh056_2.geometry}
        material={materials["YellowGlass.008"]}
      />
      <group receiveShadow castShadow visible={glamoure.vanityOverhead}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh059.geometry}
          material={materials["Brass_Yellow.011"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh059_1.geometry}
          material={materials["Self_Illumin_emissive.011"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh061.geometry}
        material={materials["Glam_FloorMsiLuxorRoyalGoldSaturation1.5-white0"]}
        visible={glamoure.msiLuxorRoyalLink}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh061.geometry}
        material={
          materials["Glam_FloorMSIHarlowShevronBlueSaturation 1.5-Grey0"]
        }
        visible={glamoure.msiHarlowChevron}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh061.geometry}
        visible={!glamoure.msiHarlowChevron && !glamoure.msiLuxorRoyalLink}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh061_1.geometry}
        material={materials.Glam_WallMSICalcattaMarbella}
        visible={glamoure.msiCalacattaMarbella}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh061_1.geometry}
        material={materials.Glam_WallSavoyAzula}
        visible={glamoure.msiSavoy}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh061_1.geometry}
        visible={!glamoure.msiSavoy && !glamoure.msiCalacattaMarbella}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh044.geometry}
        material={materials.WoodMDFWhite}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh044_1.geometry}
        material={materials.Brass}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh044_2.geometry}
        material={materials["Material.001"]}
      />
      <group receiveShadow castShadow visible={glamoure.comode}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["6428"].geometry}
          material={materials.CERAMIC}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["6428_1"].geometry}
          material={materials.aluminium}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh135.geometry}
        material={materials.ShampooTexture}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh135_1.geometry}
        material={materials.BasicBlackPlastic}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh136.geometry}
        material={materials.BuddhaImage}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh136_1.geometry}
        material={materials.ShampooTexture}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh136_2.geometry}
        material={materials.BasicBlackPlastic}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh137.geometry}
        material={materials.Brass}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh137_1.geometry}
        material={materials["MarbleWhite.010"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh137_2.geometry}
        material={materials["Ceramic.010"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh137_3.geometry}
        material={materials.MaterialMDFGrey}
      />
      <group receiveShadow castShadow visible={glamoure.mirror}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["35574001"].geometry}
          material={materials.Brass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["35574001_1"].geometry}
          material={mirrorGlobalMaterial}
        />
      </group>
      <group receiveShadow castShadow visible={glamoure.vanitySconce}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["31775_SC01"].geometry}
          material={materials["Brass_Yellow.011"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["31775_SC01_1"].geometry}
          material={materials["Self_Illumin_emissive.011"]}
        />
      </group>
      <group receiveShadow castShadow visible={glamoure.showerGlassDoor}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["701726_10L001"].geometry}
          material={materials.Brass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["701726_10L001_1"].geometry}
          material={materials.ShowerGlassMain}
        />
      </group>
      <group receiveShadow castShadow visible={glamoure.showerHeadTrimKit}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.TS27404_4001.geometry}
          material={materials.Brass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.TS27404_4001_1.geometry}
          material={materials.GreyPlastic}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh141.geometry}
        material={materials.aluminium}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh141_1.geometry}
        material={materials["GreyPlastic.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh141_2.geometry}
        material={materials.WhitePVC}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh148.geometry}
        material={materials["Glam_FloorMsiLuxorRoyalGoldSaturation1.5-white0"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh148_1.geometry}
        material={materials.Glam_FloorAccentTileLinkIvory}
        visible={glamoure.msiSande}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh148_1.geometry}
        visible={!glamoure.msiSande}
      />
    </group>
  );
}

useGLTF.preload("models/Glamoure.glb");

export function Urban(props) {
  const { nodes, materials } = useGLTF("models/Urban.glb");
  const urban = props.urban;
  const emserHeksaMaterial = new MeshStandardMaterial({
    map: new TextureLoader().load(
      "/textures/Urban_WallTilesEmserHeksaWhite.jpg",
      (texture) => (texture.flipY = false)
    ),
    roughness: 0.333,
    metalness: 0.065,
  });
  return (
    <group receiveShadow castShadow {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Urban_TowelHook.geometry}
        material={materials.UrbanDarkMetal}
        visible={urban.robeHook}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Urban_WallCabinetUpgrade001.geometry}
        material={materials.UrbanGreenwoodMDF}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Urban_OptimizedPlant.geometry}
        material={materials["Elephant_Ear_Plant_in_Black_Planter_5.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Urban_PlantBox.geometry}
        material={materials.WoodMDFWhite}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Urban_UpgradeDoorHandle002.geometry}
        material={materials.Vintage_BlackmetalStandOption}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Urban_Wallpainting.geometry}
        material={materials.Urban_Wallpainting}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Urban_Towels2001.geometry}
        material={materials["Cloth.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_Towels1001.geometry}
        material={materials["Cloth.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Urban_GreenTileWallHexpart2002.geometry}
        material={materials["Urban_WallTiles_emser_Rhythm-Black"]}
        visible={!urban.emserRakuMatte}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Urban_GreenTileWallHexpart2002.geometry}
        material={materials["Urban_WallTiles_emser_Rhythm-Silver"]}
        visible={urban.tilebarPenny}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_WickerBasket2001.geometry}
        material={materials.Wicker}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_WickerBasket2002.geometry}
        material={materials.Wicker}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Urban_PerfumeBottlesticks.geometry}
        material={materials["cortiça_difusor.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Urban_Towels2002.geometry}
        material={materials["Cloth.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Urban_StarTilesFloor.geometry}
        material={materials.Urban_FloorDaltileMemoir_jewelBlack}
        visible={urban.daltileMemoir}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Urban_StarTilesFloor.geometry}
        visible={!urban.daltileMemoir}
      />
      <group
        receiveShadow
        castShadow
        position={[-1.115, 0.233, -0.086]}
        rotation={[0, 1.571, 0]}
        scale={[0.00252, 0.00252, 0.0023]}
        visible={urban.tub}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh154.geometry}
          material={materials.CERAMIC}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh154_1.geometry}
          material={materials.aluminium}
        />
      </group>
      <group
        receiveShadow
        castShadow
        position={[-1.039, 0.008, -0.114]}
        scale={0.025}
        visible={false}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh160.geometry}
          material={materials.CERAMIC}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh160_1.geometry}
          material={materials.aluminium}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Urban_StarTilesFloor001.geometry}
        material={
          materials[
            "Urban_WallTiles-Tilebar-Penny_Hue_BluePlumLimeOrangeGreyWhite"
          ]
        }
      />
      <group
        receiveShadow
        castShadow
        position={[-1.6, -0.118, 1.471]}
        scale={0.025}
        visible={urban.showerHeadWithTubSpoutTrimSet}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh068.geometry}
          material={materials.UrbanChrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh068_1.geometry}
          material={materials.UrbanDarkMetal}
        />
      </group>
      <group
        receiveShadow
        castShadow
        position={[-1.438, -0.118, 1.471]}
        scale={0.025}
        visible={false}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh168.geometry}
          material={materials.UrbanChrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh168_1.geometry}
          material={materials.UrbanDarkMetal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh168_2.geometry}
          material={materials.Brass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh168_3.geometry}
          material={materials.GreyPlastic}
        />
      </group>
      <group receiveShadow castShadow visible={urban.toiletPaperHolder}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BathroomBase_Extra_Toilet_PaperRoll003.geometry}
          material={materials["Toilet paper"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh067.geometry}
          material={materials.UrbanChrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh067_1.geometry}
          material={materials.UrbanDarkMetal}
        />
      </group>
      <group receiveShadow castShadow visible={urban.vanityOverhead}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh071.geometry}
          material={materials.UrbanDarkMetal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh071_1.geometry}
          material={materials.Glass_ClearMat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh071_2.geometry}
          material={materials.EmissiveMaterial}
        />
      </group>
      <group receiveShadow castShadow visible={urban.towelRing}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh073.geometry}
          material={materials.UrbanChrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh073_1.geometry}
          material={materials.UrbanDarkMetal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh073_2.geometry}
          material={materials.UrbanCloth}
        />
      </group>
      <group receiveShadow castShadow visible={urban.towelBar}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh074.geometry}
          material={materials.UrbanChrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh074_1.geometry}
          material={materials.UrbanDarkMetal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh074_2.geometry}
          material={materials.UrbanCloth}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh080.geometry}
        material={materials.UrbanGreenwoodMDF}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh080_1.geometry}
        material={materials["MarbleWhite.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh080_2.geometry}
        material={materials["Ceramic.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh080_3.geometry}
        material={materials.ChromeHandles}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh078.geometry}
        material={materials.StuccoWalls}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh078_1.geometry}
        material={MeshTransmissionMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh078_2.geometry}
        material={materials.WhitePlasticPVC}
      />
      <group receiveShadow castShadow visible={urban.lightFixture}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh070.geometry}
          material={materials.UrbanDarkMetal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh070_1.geometry}
          material={materials.Glass_ClearMat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh070_2.geometry}
          material={materials.EmissiveMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh070_3.geometry}
          material={materials["Glass_clear.008"]}
        />
      </group>
      <group receiveShadow castShadow visible={urban.mirror}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh072.geometry}
          material={materials.UrbanDarkMetal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh072_1.geometry}
          material={materials.MirrorMainmaterial}
        />
      </group>
      <group receiveShadow castShadow visible={false}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["701696_L"].geometry}
          material={materials.aluminium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["701696_L_1"].geometry}
          material={materials.ShowerGlassMain}
        />
      </group>
      <group receiveShadow castShadow visible={urban.lavFaucetDeckMount}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh165.geometry}
          material={materials.UrbanChrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh165_1.geometry}
          material={materials.UrbanDarkMetal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh165.geometry}
          material={materials.UrbanChrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh165_1.geometry}
          material={materials.UrbanDarkMetal}
        />
      </group>
      <group receiveShadow castShadow visible={urban.comode}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh036.geometry}
          material={materials.CERAMIC}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh036_1.geometry}
          material={materials.aluminium}
        />
      </group>
      {/*  */}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh159.geometry}
        material={materials["Urban_WallTiles_emser_Rhythm-Black"]}
        visible={urban.emserRythm}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh159.geometry}
        material={emserHeksaMaterial}
        visible={urban.emserHeksa}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh147.geometry}
        material={materials.ShampooTexture}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh147_1.geometry}
        material={materials.BasicBlackPlastic}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh150.geometry}
        material={materials.BuddhaImage}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh150_1.geometry}
        material={materials.ShampooTexture}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh150_2.geometry}
        material={materials.BasicBlackPlastic}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh163.geometry}
        material={materials.Glass}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh163_1.geometry}
        material={materials.ShampooTexture}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh163_2.geometry}
        material={materials.BasicBlackPlastic}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh166.geometry}
        material={materials.Glass}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh166_1.geometry}
        material={materials.ShampooTexture}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh166_2.geometry}
        material={materials.BasicBlackPlastic}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh144.geometry}
        material={materials["Material #120.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh144_1.geometry}
        material={materials["Material #121.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh144_2.geometry}
        material={materials["Material #122.001"]}
      />
      <group receiveShadow castShadow visible={urban.lavFaucet}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["26437_4"].geometry}
          material={materials.UrbanChrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["26437_4_1"].geometry}
          material={materials.UrbanDarkMetal}
        />
      </group>
      <group receiveShadow castShadow visible={urban.vanitySconce}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["31761_SC01001"].geometry}
          material={materials.UrbanDarkMetal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["31761_SC01001_1"].geometry}
          material={materials.Glass_ClearMat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["31761_SC01001_2"].geometry}
          material={materials.EmissiveMaterial}
        />
      </group>
      <group receiveShadow castShadow visible={urban.medicineCabinet}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["35570"].geometry}
          material={materials.UrbanDarkMetal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["35570_1"].geometry}
          material={materials.MirrorMainmaterial}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh161.geometry}
        material={materials.aluminium}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh161_1.geometry}
        material={materials["GreyPlastic.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh161_2.geometry}
        material={materials.WhitePVC}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh161_3.geometry}
        material={materials["03 - Default Slot #4"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh161_4.geometry}
        material={materials["03 - Default Slot #5"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh161_5.geometry}
        material={materials["03 - Default Slot #6"]}
      />
      <group receiveShadow castShadow visible={urban.handShower}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh139.geometry}
          material={materials.UrbanChrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh139_1.geometry}
          material={materials.UrbanDarkMetal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh139_2.geometry}
          material={materials.GreyPlastic}
        />
      </group>
      <group receiveShadow castShadow visible={urban.transferTrim}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.T23955_4004.geometry}
          material={materials.UrbanChrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.T23955_4004_1.geometry}
          material={materials.UrbanDarkMetal}
        />
      </group>
      <group receiveShadow castShadow visible={false}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.T23955_4001.geometry}
          material={materials.UrbanChrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.T23955_4001_1.geometry}
          material={materials.UrbanDarkMetal}
        />
      </group>
    </group>
  );
}

useGLTF.preload("models/Urban.glb");

export function Vintage(props) {
  const { nodes, materials } = useGLTF("models/Vintage.glb");
  const vintage = props.vintage;
  mirrorGlobalMaterial = materials.MirrorMainmaterial;
  const msiEdenStatuaryWall = new MeshPhysicalMaterial({
    roughness: 0.135,
    metalness: 0.211,
    map: new TextureLoader().load(
      "/textures/msiEdenWall/msiEdenWall.jpg",
      (texture) => {
        texture.flipY = false;
        texture.wrapS = 1000;
        texture.wrapT = 1000;
        texture.repeat.set(1, 1);
      }
    ),
    normalMap: new TextureLoader().load(
      "/textures/msiEdenWall/MSI_TilesNormalmap.jpg",
      (texture) => {
        texture.flipY = false;
        texture.wrapS = 1000;
        texture.wrapT = 1000;
        texture.repeat.set(1, 1);
      }
    ),
  });
  const msiKayaWall = new MeshPhysicalMaterial({
    roughness: 0.859,
    metalness: 1,
    map: new TextureLoader().load(
      "/textures/msiKayaWall/msiKayaWall.jpg",
      (texture) => {
        texture.flipY = false;
        texture.wrapS = 1000;
        texture.wrapT = 1000;
      }
    ),
    normalMap: new TextureLoader().load(
      "/textures/msiKayaWall/MSI_TilesNormalmap.jpg",
      (texture) => {
        texture.flipY = false;
        texture.wrapS = 1000;
        texture.wrapT = 1000;
      }
    ),
  });
  const msiHexagono = new MeshPhysicalMaterial({
    roughness: 0.073,
    metalness: 0.324,
    map: new TextureLoader().load(
      "/textures/msiHexagono/msiHexagono.jpg",
      (texture) => {
        texture.flipY = false;
        texture.wrapS = 1000;
        texture.wrapT = 1000;
        texture.repeat.set(1, 1.4);
      }
    ),
    normalMap: new TextureLoader().load(
      "/textures/msiHexagono/msiHexagonoNormal.jpg",
      (texture) => {
        texture.flipY = false;
        texture.wrapS = 1000;
        texture.wrapT = 1000;
        texture.repeat.set(1, 1.4);
      }
    ),
  });
  const msi2InchBlackHex = new MeshStandardMaterial({
    roughness: 0.102,
    metalness: 0.386,
    map: new TextureLoader().load(
      "/textures/msi2InchBlackHex/msi2InchHexBlack.jpg",
      (texture) => {
        texture.flipY = false;
        texture.wrapS = 1000;
        texture.wrapT = 1000;
        texture.repeat.set(1, 1.4);
      }
    ),
    normalMap: new TextureLoader().load(
      "/textures/msi2InchBlackHex/msi2InchHexBlackNormal.jpg",
      (texture) => {
        texture.flipY = false;
        texture.wrapS = 1000;
        texture.wrapT = 1000;
        texture.repeat.set(1, 1.4);
      }
    ),
  });
  console.log(materials);
  return (
    <group receiveShadow castShadow {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Vint_WallTowelHook.geometry}
        material={materials.Vintage_BlackmetalStandOption}
        visible={vintage.robeHook}
      />
      <group receiveShadow castShadow visible={vintage.toiletPaperHolder}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Toiletpaper_Vintage.geometry}
          material={materials["Toilet paper"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vintage_ToiletPaper.geometry}
          material={materials.Vintage_BlackmetalStandOption}
        />
      </group>
      <group receiveShadow castShadow visible={vintage.towelBar}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vint_TowelSeparate.geometry}
          material={materials["TowelTexture.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vint_WallTowelHolderLarge.geometry}
          material={materials.Vintage_BlackmetalStandOption}
        />
      </group>
      <group receiveShadow castShadow visible={vintage.towelRing}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vint_TowelSmallSeparated.geometry}
          material={materials["TowelTexture.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vintage_WallTowelsmall.geometry}
          material={materials.Vintage_BlackmetalStandOption}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Vintage_WoodWallGreen.geometry}
        material={materials.Vintage_WoodMDF}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Vintage_FaucetPrimary.geometry}
        material={materials.Vintage_BlackmetalStandOption}
        visible={vintage.lavFaucetDeckMount}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Vintage_UpgradeDoorHandle001.geometry}
        material={materials.Vintage_BlackmetalStandOption}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Vintage_WallTilesAccentOnly.geometry}
        material={materials["MSI_Ragallo_Calacatta.003"]}
      />
      {/*  */}
      <group receiveShadow castShadow visible={vintage.msiEdenStatuaryWall}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vint_ShowerTileWalls001.geometry}
          material={msiEdenStatuaryWall}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vintage_WainscottingWallTiles.geometry}
          material={msiEdenStatuaryWall}
        />
      </group>
      <group receiveShadow castShadow visible={vintage.msiKayaWall}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vint_ShowerTileWalls001.geometry}
          material={msiKayaWall}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vintage_WainscottingWallTiles.geometry}
          material={msiKayaWall}
        />
      </group>
      <group receiveShadow castShadow visible={vintage.msiRagalloWall}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vint_ShowerTileWalls001.geometry}
          material={materials.MSI_Ragallo_Calacatta}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vintage_WainscottingWallTiles.geometry}
          material={materials.MSI_Ragallo_Calacatta}
        />
      </group>
      <group
        receiveShadow
        castShadow
        visible={
          !vintage.msiRagalloWall &&
          !vintage.msiKayaWall &&
          !vintage.msiEdenStatuaryWall
        }
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vint_ShowerTileWalls001.geometry}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vintage_WainscottingWallTiles.geometry}
        />
      </group>
      {/*  */}
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Vintage_Upgrade_P_-_Upgrade_handles_cross_-_K-98068-3-SN"]
            .geometry
        }
        material={materials.Vintage_BlackmetalStandOption}
        visible={false}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Vintage_Upgrade_FaucetHandles-t-Artifacts-K-98068-6NRO-SN"]
            .geometry
        }
        material={materials.Vintage_BlackmetalStandOption}
        visible={vintage.lavFaucet}
      />
      <group
        receiveShadow
        castShadow
        position={[-1.115, 0.233, -0.086]}
        rotation={[0, 1.571, 0]}
        scale={[0.00252, 0.00252, 0.0023]}
        visible={vintage.tub}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh153.geometry}
          material={materials.CERAMIC}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh153_1.geometry}
          material={materials.aluminium}
        />
      </group>
      <group
        receiveShadow
        castShadow
        position={[-1.039, 0.008, -0.114]}
        scale={0.025}
        visible={false}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh006.geometry}
          material={materials.CERAMIC}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh006_1.geometry}
          material={materials.aluminium}
        />
      </group>
      <group
        receiveShadow
        castShadow
        position={[-1.597, -0.017, 1.435]}
        scale={0.025}
        visible={false}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh090.geometry}
          material={materials.Vintage_BlackmetalStandOption}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh090_1.geometry}
          material={materials["GreyPlastic.001"]}
        />
      </group>
      <group receiveShadow castShadow>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["Vint_Upgrade2-Showerhead&Valve001"].geometry}
          material={materials.Vintage_BlackmetalStandOption}
          position={[-1.91, -0.002, 1.484]}
          scale={0.025}
          visible={false}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["Vint_Upgrade2-Showerhead&Valve003"].geometry}
          material={materials.Vintage_BlackmetalStandOption}
          visible={vintage.handShower}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_Towels1003.geometry}
        material={materials.Cloth}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SPA_Towels2002.geometry}
        material={materials.Cloth}
      />
      <group receiveShadow castShadow visible={vintage.lightFixture}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh086.geometry}
          material={materials.UrbanDarkMetal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh086_1.geometry}
          material={materials.Glass_ClearMat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh086_2.geometry}
          material={materials.EmissiveMaterial}
        />
      </group>
      <group receiveShadow castShadow>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh095.geometry}
          material={materials["WhitePVC.028"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh095_1.geometry}
          material={MeshTransmissionMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh095_2.geometry}
          material={materials["Stucco.015"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh097.geometry}
        material={materials.StuccoWalls}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh097_1.geometry}
        material={materials["walnutWood.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh097_2.geometry}
        material={materials["Soap_Orange.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh097_3.geometry}
        material={materials.SoapMat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh097_4.geometry}
        material={materials.BasketMat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh097_5.geometry}
        material={materials.Urn1mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh097_6.geometry}
        material={materials.Urn2mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh099.geometry}
        material={materials.UrbanDarkMetal}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh099_1.geometry}
        material={materials.Glass_ClearMat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh099_2.geometry}
        material={materials.EmissiveMaterial}
      />
      <group receiveShadow castShadow visible={vintage.mirror}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh100.geometry}
          material={materials.UrbanDarkMetal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh100_1.geometry}
          material={materials.MirrorMainmaterial}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh079.geometry}
        material={materials["Material #1121"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh079_1.geometry}
        material={materials["Glass.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh079_2.geometry}
        material={materials.water}
      />
      <group receiveShadow castShadow visible={vintage.comode}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["6428001"].geometry}
          material={materials.CERAMIC}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["6428001_1"].geometry}
          material={materials.aluminium}
        />
      </group>
      <group receiveShadow castShadow visible={vintage.medicineCabinet}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["35574"].geometry}
          material={materials.UrbanDarkMetal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["35574_1"].geometry}
          material={materials.MirrorMainmaterial}
        />
      </group>
      <group receiveShadow castShadow visible={vintage.vanitySconce}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["72584"].geometry}
          material={materials["Glass_clear.008"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["72584_1"].geometry}
          material={materials.UrbanDarkMetal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["72584_2"].geometry}
          material={materials.Glass_ClearMat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["72584_3"].geometry}
          material={materials.EmissiveMaterial}
        />
      </group>
      <group receiveShadow castShadow visible={vintage.showerGlassDoor}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh016.geometry}
          material={materials.ShowerGlassMain}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh016_1.geometry}
          material={materials.OilRubbedBronze}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh034.geometry}
        material={materials.aluminium}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh034_1.geometry}
        material={materials["GreyPlastic.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh034_2.geometry}
        material={materials.WhitePVC}
      />
      {/*  */}
      <group receiveShadow castShadow visible={vintage.msiEdenStatuaryHex}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vintage_ShowerTilesFloorOnly.geometry}
          material={materials.showerOnly_MSI_statuaryhex}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh176.geometry}
          material={materials.showerOnly_MSI_statuaryhex}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh176_1.geometry}
          material={materials["Eden_Statuary Hex"]}
        />
      </group>
      <group receiveShadow castShadow visible={vintage.msiRagallo}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vintage_ShowerTilesFloorOnly.geometry}
          material={materials.MSI_Ragallo_Calacatta}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh176.geometry}
          material={materials.MSI_Ragallo_Calacatta}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh176_1.geometry}
          material={materials.MSI_Ragallo_Calacatta}
        />
      </group>
      <group receiveShadow castShadow visible={vintage.msiEdenStatuary}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vintage_ShowerTilesFloorOnly.geometry}
          material={msiEdenStatuaryWall}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh176.geometry}
          material={msiEdenStatuaryWall}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh176_1.geometry}
          material={msiEdenStatuaryWall}
        />
      </group>
      <group receiveShadow castShadow visible={vintage.msiHexagono}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vintage_ShowerTilesFloorOnly.geometry}
          material={msiHexagono}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh176.geometry}
          material={msiHexagono}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh176_1.geometry}
          material={msiHexagono}
        />
      </group>
      <group receiveShadow castShadow visible={vintage.msi2InchBlackHex}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vintage_ShowerTilesFloorOnly.geometry}
          material={msi2InchBlackHex}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh176.geometry}
          material={msi2InchBlackHex}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh176_1.geometry}
          material={msi2InchBlackHex}
        />
      </group>
      <group
        receiveShadow
        castShadow
        visible={
          !vintage.msiEdenStatuaryHex &&
          !vintage.msiRagallo &&
          !vintage.msiEdenStatuary &&
          !vintage.msiHexagono &&
          !vintage.msi2InchBlackHex
        }
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Vintage_ShowerTilesFloorOnly.geometry}
        />
        <mesh castShadow receiveShadow geometry={nodes.Mesh176.geometry} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh176_1.geometry} />
      </group>
      {/*  */}
      <group receiveShadow castShadow visible={false}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh171.geometry}
          material={materials.Vintage_BlackmetalStandOption}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh171_1.geometry}
          material={materials["GreyPlastic.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh171_2.geometry}
          material={materials.UrbanDarkMetal}
        />
      </group>
      <group receiveShadow castShadow visible={vintage.vanityAll}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh181.geometry}
          material={materials.VanityOption_StoneTop}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh181_1.geometry}
          material={materials.Metal_chrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh181_2.geometry}
          material={materials.PolishedNickelStandoption}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh181_3.geometry}
          material={materials.WoodVeneer}
        />
      </group>
      <group receiveShadow castShadow visible={false}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh182.geometry}
          material={materials.Vintage_BlackmetalStandOption}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh182_1.geometry}
          material={materials.VanityOption_StoneTop}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh182_2.geometry}
          material={materials.Metal_chrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh182_3.geometry}
          material={materials.WoodVeneer}
        />
      </group>
      <group receiveShadow castShadow visible={!vintage.vanityAll}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh183.geometry}
          material={materials.Vintage_BlackmetalStandOption}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh183_1.geometry}
          material={materials.VanityOption_StoneTop}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh183_2.geometry}
          material={materials.Metal_chrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh183_3.geometry}
          material={materials.WoodVeneer}
        />
      </group>
    </group>
  );
}

useGLTF.preload("models/Vintage.glb");

export function Contemporary(props) {
  const { nodes, materials } = useGLTF("models/Contemporary.glb");
  const contemporary = props.contemporary;
  return (
    <group receiveShadow castShadow {...props} dispose={null}>
      <group receiveShadow castShadow visible={contemporary.towelBar}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Contemp_TowelHolderLarge001001.geometry}
          material={materials.Brass}
          castShadow
          receiveShadow
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.TowelSeparate001.geometry}
          material={materials["TowelTexture.002"]}
          castShadow
          receiveShadow
        />
      </group>
      <group receiveShadow castShadow visible={contemporary.towelRing}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Contemp_TowelHolderSmall001.geometry}
          material={materials.Brass}
          castShadow
          receiveShadow
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.TowelSmallSeparated001.geometry}
          material={materials["TowelTexture.002"]}
          castShadow
          receiveShadow
        />
      </group>
      <group receiveShadow castShadow visible={contemporary.lavFaucetDeckMount}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Contemp_WashBasinFaucet.geometry}
          material={materials.Brass}
          castShadow
          receiveShadow
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Glam_UpgradeDoorHandle.geometry}
        material={materials.Brass}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Contemp_PaintingWall.geometry}
        material={materials["Custom (4)"]}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.TowelSeparate002.geometry}
        material={materials["TowelTexture.002"]}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Contemp_TowelHook.geometry}
        material={materials.Brass}
        visible={contemporary.robeHook}
        castShadow
        receiveShadow
      />
      <group receiveShadow castShadow visible={contemporary.lavFaucet}>
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["Contemp_Upgrade-OptionfaucetComposed-K-73167-4-BL"].geometry
          }
          material={materials.Brass}
          castShadow
          receiveShadow
        />
      </group>
      <group receiveShadow castShadow visible={contemporary.lavFaucetWallMount}>
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["Contemp_Upgrade-OptionfaucetWall-parallel-K-22567-4"]
              .geometry
          }
          material={materials.Brass}
          castShadow
          receiveShadow
        />
      </group>
      <group
        receiveShadow
        castShadow
        position={[0.12, -0.009, -2.991]}
        rotation={[0, 1.571, 0]}
        scale={0.025}
        visible={false}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh112.geometry}
          material={materials.Brass}
          castShadow
          receiveShadow
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh112_1.geometry}
          material={materials.WhitePVC}
          castShadow
          receiveShadow
        />
      </group>
      <group
        receiveShadow
        castShadow
        position={[-0.188, -0.026, -2.978]}
        rotation={[0, 1.571, 0]}
        scale={0.025}
        visible={false}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh101.geometry}
          material={materials.Brass}
          castShadow
          receiveShadow
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh101_1.geometry}
          material={materials["GreyPlastic.002"]}
          castShadow
          receiveShadow
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh101_2.geometry}
          material={materials.GreyPlastic}
          castShadow
          receiveShadow
        />
      </group>
      <group
        receiveShadow
        castShadow
        position={[-1.115, 0.233, -0.086]}
        rotation={[0, 1.571, 0]}
        scale={[0.00252, 0.00252, 0.0023]}
        visible={contemporary.tub}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh064.geometry}
          material={materials.CERAMIC}
          castShadow
          receiveShadow
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh064_1.geometry}
          material={materials.aluminium}
          castShadow
          receiveShadow
        />
      </group>
      <group
        receiveShadow
        castShadow
        position={[-1.039, 0.008, -0.114]}
        scale={0.025}
        visible={false}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh132.geometry}
          material={materials.CERAMIC}
          castShadow
          receiveShadow
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh132_1.geometry}
          material={materials.aluminium}
          castShadow
          receiveShadow
        />
      </group>
      <group receiveShadow castShadow visible={contemporary.mirror}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh110.geometry}
          material={materials.UrbanDarkMetal}
          castShadow
          receiveShadow
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh110_1.geometry}
          material={materials.MirrorMainmaterial}
          castShadow
          receiveShadow
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh110_2.geometry}
          material={materials["Self_Illumin_emissive.001"]}
          castShadow
          receiveShadow
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Urban_ShowerTilesOnly.geometry}
        material={materials.Emser_Prodigy_Mill24x12Option}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh088.geometry}
        material={materials.WoodWalnutDark}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh088_1.geometry}
        material={materials.MetalDark}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh088_2.geometry}
        material={materials["Ceramic.002"]}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh088_3.geometry}
        material={materials["Marble.001"]}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh088_4.geometry}
        material={materials.BlackMetalMatte}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh113.geometry}
        material={materials.Emser_PublicOption}
        visible={contemporary.emserCitizen}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh113.geometry}
        visible={!contemporary.emserCitizen}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh113_1.geometry}
        material={materials.Emser_Prodigy_Agnesi24x12Option}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh114.geometry}
        material={materials["WhitePVC.028"]}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh114_1.geometry}
        material={
          <mesh
            castShadow
            receiveShadowTransmissionMaterial
            castShadow
            receiveShadow
          />
        }
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh114_2.geometry}
        material={materials["Stucco.015"]}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh115.geometry}
        material={materials.WoodWalnutDark}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh115_1.geometry}
        material={materials.MetalDark}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh121.geometry}
        material={materials["Material #1295.001"]}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh121_1.geometry}
        material={materials["Material #1298.001"]}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh122.geometry}
        material={materials.UrbanDarkMetal}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh122_1.geometry}
        material={materials["Self_Illumin_emissive.001"]}
        castShadow
        receiveShadow
      />
      <group receiveShadow castShadow visible={contemporary.showerGlassDoor}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["701696_L001"].geometry}
          material={materials.aluminium}
          castShadow
          receiveShadow
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["701696_L001_1"].geometry}
          material={materials.ShowerGlassMain}
          castShadow
          receiveShadow
        />
      </group>
      <group receiveShadow castShadow visible={contemporary.comode}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh017.geometry}
          material={materials.CERAMIC}
          castShadow
          receiveShadow
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh017_1.geometry}
          material={materials.aluminium}
          castShadow
          receiveShadow
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh134.geometry}
        material={materials.Daltile_Nero}
        visible={contemporary.sterlina}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh134.geometry}
        visible={!contemporary.sterlina}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh134_1.geometry}
        material={materials.Daltile_EmersonWoodHickory}
        visible={contemporary.woodHickoryPinePlank}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh134_1.geometry}
        visible={!contemporary.woodHickoryPinePlank}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh134_2.geometry}
        material={materials.WhiteGrout}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh134_3.geometry}
        material={materials.Emser_Prodigy_Pascal24x24}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh134_4.geometry}
        material={materials.Emser_Prodigy_Pascal24x12}
        castShadow
        receiveShadow
      />
      <group receiveShadow castShadow visible={false}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh172.geometry}
          material={materials.Brass}
          castShadow
          receiveShadow
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh172_1.geometry}
          material={materials["GreyPlastic.002"]}
          castShadow
          receiveShadow
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh142.geometry}
        material={materials.aluminium}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh142_1.geometry}
        material={materials["GreyPlastic.002"]}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh142_2.geometry}
        material={materials.WhitePVC}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh142_3.geometry}
        material={materials["03 - Default Slot #4"]}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh142_4.geometry}
        material={materials["03 - Default Slot #5"]}
        castShadow
        receiveShadow
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh142_5.geometry}
        material={materials["03 - Default Slot #6"]}
        castShadow
        receiveShadow
      />
    </group>
  );
}

useGLTF.preload("models/Contemporary.glb");
