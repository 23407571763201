
import * as holdEvent from "hold-event"
export function getKeyDetails() {
    const KEYCODE = {
        W: 'KeyW',
        A: 'KeyA',
        S: 'KeyS',
        D: 'KeyD',
        ARROW_LEFT: 37,
        ARROW_UP: 38,
        ARROW_RIGHT: 39,
        ARROW_DOWN: 40,
    };

    const wKey = new holdEvent.KeyboardKeyHold(KEYCODE.W, 16.666);
    const aKey = new holdEvent.KeyboardKeyHold(KEYCODE.A, 16.666);
    const sKey = new holdEvent.KeyboardKeyHold(KEYCODE.S, 16.666);
    const dKey = new holdEvent.KeyboardKeyHold(KEYCODE.D, 16.666);

    return { wKey, aKey, sKey, dKey }
}