// import { useSnapshot } from "valtio";
// import Store from "../../store/Store";
// import { GlbModel } from "./assets/GlbModel";

// export const VariantModel = ({  }) => {

//     const snap = useSnapshot(Store);
//     const { variant, data } = snap;
//     const variantData = data.bathroom_type.find((item) => item.name === variant);

//     const variantModels = variantData?.models || [];
//     const variantModelURLs = variantModels.map((model) => snap.base_url + variantData.dir_name + "/" + model + ".glb");
//     return (
//         <>
//            <object3D>
//                 {variantModelURLs.map((url, index) => {
//                     let castShadow = false;
//                     let receiveShadow = false;
//                     return <GlbModel key={index} url={url} castShadow={castShadow} receiveShadow={receiveShadow} />
//                 })}
//             </object3D>
//         </>
//     );
// };

import { useSnapshot } from "valtio";
import Store from "../../store/Store";
import { Spa, Glamoure, Urban, Vintage, Contemporary } from "./ModelsTemp";
import { Suspense } from "react";

export const VariantModel = ({ styleCheckedOptions }) => {
  const snap = useSnapshot(Store);
  const { variant } = snap;
  const { spa, contemporary, glamoure, urban, vintage } = styleCheckedOptions;

  return (
    <>
      <Suspense>
        <Spa visible={variant === "SPA"} spaCheckedOptions={spa} />
      </Suspense>
      <Suspense>
        <Contemporary
          visible={variant === "Contemporary"}
          contemporary={contemporary}
        />
      </Suspense>
      <Suspense>
        <Glamoure visible={variant === "Glamoure"} glamoure={glamoure} />
      </Suspense>
      <Suspense>
        <Urban visible={variant === "Urban"} urban={urban} />
      </Suspense>
      <Suspense>
        <Vintage visible={variant === "Vintage"} vintage={vintage} />
      </Suspense>
    </>
  );
};
