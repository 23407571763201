// Button.js

import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ label, onClick, disabled }) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            style={{
                padding: '10px 15px',
                fontSize: '16px',
                backgroundColor: disabled ? '#ccc' : '#d0d0d0',
                color: '#000',
                border: 'none',
                borderRadius: '5px',
                cursor: disabled ? 'not-allowed' : 'pointer',
                transition: 'background-color 0.3s ease', // Adding transition for a smoother effect
            }}
            // Adding hover effect
            onMouseEnter={(e) => {
                e.target.style.backgroundColor = disabled ? '#ccc' : '#9b9b9b';
            }}
            onMouseLeave={(e) => {
                e.target.style.backgroundColor = disabled ? '#ccc' : '#d0d0d0';
            }}
        >
            {label}
        </button>
    );
};

Button.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

export default Button;
