import { N8AO, EffectComposer } from "@react-three/postprocessing";

export function Effects() {
  return (
    <EffectComposer disableNormalPass>
      <N8AO
        aoRadius={50}
        intensity={7}
        denoiseSamples={16}
        distanceFalloff={0.16}
        screenSpaceRadius={true}
      />
      {/* <ToneMapping /> */}
    </EffectComposer>
  );
}
