const inputData = {
  default_camera_position: [1.05, 1.5, 0.77],
  default_camera_rotation: [1.1, 1.35, 0],
  base_model_url: "BASE_MODEL",
  base_models: [
    "BathroomBase_CabinetWalls.001",
    "BathroomBase_Ceiling",
    "BathroomBase_MainBuildingWalls",
    "Door_BaseNew",
    "ExteriorViewFake",
    "SPA_Extra_SoapDispenser_GEO",
    "SPA_Extra_toothbrushHolder_GEO",
  ],
  bathroom_type: [
    {
      name: "SPA",
      dir_name: "SPA",
      models: [
        "BathroomBase_Extra_Toilet_PaperRoll.001",
        "SPA_AdditionalGroutdetail",
        "SPA_A_ToilerRollHolder_14377",
        "SPA_BathroomCabinetHandles",
        "SPA_BathroomCabinetMarble",
        "SPA_BathroomCabinets",
        "SPA_Cabinetwall2",
        "SPA_CabinetWood",
        "SPA_CeilingLight_litfad",
        "SPA_DoorHandle",
        "SPA_Extra_toiletBrushHolder_GEO",
        "SPA_FakeLightFullEmissive",
        "SPA_FramePictureBuddha",
        "SPA_MirrorEssentials_26052",
        "SPA_NewFloorTiles",
        "SPA_PlantDecor",
        "SPA_PrimarySconce_23464_SCLED",
        "SPA_P_FaucetPurist",
        "SPA_Setting1-ShowerTub_Primary",
        "SPA_shampoo003",
        "SPA_shampoo004",
        "SPA_Shampoo1",
        "SPA_shampoo2",
        "SPA_shelves_veneer",
        "SPA_ShowerPrimaryForTub_Purist",
        "SPA_sinkCaxton",
        "SPA_Toilet_1381_P_KohlerVeilToilet",
        "SPA_TowelHook",
        "SPA_TowelRing_Purist",
        "SPA_Towels1",
        "SPA_Towels2",
        "SPA_Towerbar_Purist",
        "SPA_WallGainswoodShowershield",
        "SPA_WallTilesType1",
        "SPA_WallwindowsModular",
        "SPA_WickerBasket1",
        "SPA_WickerBasket2",
      ],
      upgrades: [],
    },
    {
      name: "Contemporary",
      dir_name: "Contemporary",
      models: [
        "Contemporary_ShowerCabinet",
        "Contemporary_WallWindows",
        "Contemporary_WashBasin",
        "Contemp_DecorTowelThing.001",
        "Contemp_PaintingWall",
        "Contemp_Setting1-ShowerTub_Primary.001",
        "Contemp_TilesFloor",
        "Contemp_Toilet_1381_P_KohlerVeilToilet.001",
        "Contemp_TowelHolderLarge001.001",
        "Contemp_TowelHolderSmall001",
        "Contemp_TowelHook",
        "Contemp_Upgrade-ShowerDoorComposed-K-701696-G79",
        "Contemp_WallTilesEdited",
        "Contemp_WashBasinFaucet",
        "Cont_CeilingLights001",
        "Cont_Mirror.001",
        "Cont_Shower&ValvePrimary",
        "Glam_UpgradeDoorHandle",
        "TowelSeparate.001",
        "TowelSeparate.002",
        "TowelSmallSeparated.001",
      ],
      upgrades: [],
    },
    {
      name: "Glamoure",
      dir_name: "Glamoure",
      models: [
        "BathroomBase_Extra_Toilet_PaperRoll.002",
        "Glamoure_UpgradeDoorHandle",
        "Glamoure_WallWindows",
        "Glamour_Setting1-ShowerTub_Primary",
        "Glamour_Toilet_StatelySkirted-K-6428-0",
        "Glam_BasinVanity",
        "Glam_FloorAccentTileOnly",
        "Glam_FloorCenterTileOnly",
        "Glam_PlantOptimized001",
        "Glam_UpgradeMirror_MedicineCabinetVerdara_K35574",
        "Glam_UpgradePossiniLight",
        "Glam_UpgradeShowerOption3-Head&ValveTrimRiff-K-S27404.001",
        "Glam_UpgradeShowerTowelHook",
        "Glam_UpgradeToiletPaperHolder",
        "Glam_UpgradeTowelHolderLarge",
        "Glam_Upgrade_faucet_K26433",
        "Glam_Upgrade_Sconce_OccasionK31175",
        "Glam_Upgrade_Showerhead&Valve_S27404-4",
        "Glam_Upgrade_TowelHolderSmall",
        "Glam_WallPainting",
        "Glam_WallTilesx",
        "SPA_Shampoo1.001",
        "SPA_shampoo2.001",
        "SPA_Towels1.002",
        "SPA_Towels2.001",
        "Urban_WallCabinetUpgrade",
      ],
      upgrades: [],
    },
    {
      name: "Urban",
      dir_name: "Urban",
      models: [
        "BathroomBase_Extra_Toilet_PaperRoll.003",
        "SPA_shampoo003.001",
        "SPA_shampoo004.001",
        "SPA_Shampoo1.002",
        "SPA_shampoo2.002",
        "SPA_Towels1.001",
        "SPA_WickerBasket2.001",
        "SPA_WickerBasket2.002",
        "Urban_CeilingLampUpg",
        "Urban_GreenTileWallHexpart2.002",
        "Urban_GreenTileWallHexpart3.001",
        "Urban_MirrorWall_Tone22x34-mirror-K-265052-BLL",
        "Urban_OptimizedPlant",
        "Urban_P - Shower Door Composed - K-701696-G79",
        "Urban_PerfumeBottlesticks",
        "Urban_PerfumeBottleSticks2",
        "Urban_PlantBox",
        "Urban_SconceLight_sconce - BelleraTwoLight-K-31762-SC02-BLL",
        "Urban_Setting1-ShowerTub_Primary",
        "Urban_ShowerOption1_Primarysetting",
        "Urban_StarTilesFloor",
        "Urban_ToiletPaperHolder",
        "Urban_Toilet_1381_P_KohlerVeilToilet",
        "Urban_TowelHolderSmall",
        "Urban_TowelHook",
        "Urban_Towels2.001",
        "Urban_Towels2.002",
        "Urban_UpgradeDoorHandle.002",
        "Urban_WallCabinetUpgrade001",
        "Urban_Wallpainting",
        "Urban_WallTowelholderLarge",
        "Urban_WallWindows",
        "Urban_WashBasin.001",
        "Urban_WashBasinFaucet_KohlerToneWidespreadBathroomFauce_e1468b5",
      ],
      upgrades: [],
    },
    {
      name: "Vintage",
      dir_name: "Urban",
      models: [
        "BathroomBase_Extra_Toilet_PaperRoll.003",
        "SPA_shampoo003.001",
        "SPA_shampoo004.001",
        "SPA_Shampoo1.002",
        "SPA_shampoo2.002",
        "SPA_Towels1.001",
        "SPA_WickerBasket2.001",
        "SPA_WickerBasket2.002",
        "Urban_CeilingLampUpg",
        "Urban_GreenTileWallHexpart2.002",
        "Urban_GreenTileWallHexpart3.001",
        "Urban_MirrorWall_Tone22x34-mirror-K-265052-BLL",
        "Urban_OptimizedPlant",
        "Urban_P - Shower Door Composed - K-701696-G79",
        "Urban_PerfumeBottlesticks",
        "Urban_PerfumeBottleSticks2",
        "Urban_PlantBox",
        "Urban_SconceLight_sconce - BelleraTwoLight-K-31762-SC02-BLL",
        "Urban_Setting1-ShowerTub_Primary",
        "Urban_ShowerOption1_Primarysetting",
        "Urban_StarTilesFloor",
        "Urban_ToiletPaperHolder",
        "Urban_Toilet_1381_P_KohlerVeilToilet",
        "Urban_TowelHolderSmall",
        "Urban_TowelHook",
        "Urban_Towels2.001",
        "Urban_Towels2.002",
        "Urban_UpgradeDoorHandle.002",
        "Urban_WallCabinetUpgrade001",
        "Urban_Wallpainting",
        "Urban_WallTowelholderLarge",
        "Urban_WallWindows",
        "Urban_WashBasin.001",
        "Urban_WashBasinFaucet_KohlerToneWidespreadBathroomFauce_e1468b5",
      ],
      upgrades: [],
    },
  ],
};
export default inputData;
