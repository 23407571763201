import { useState } from "react"
import { RoomViewEnum } from "../../enums/RoomViewEnum"
import Button from "./UIButton"
import "./UIButtons.css"
import { emitCustomEvent } from 'react-custom-events'

export const UIButtons = () => {
    let speedFactor = 5
    const movement = (inDir) => {
        emitCustomEvent(inDir, speedFactor)
    }
    const setView = (inView) => {
        emitCustomEvent('setView', inView)
    }
    const [showInstructions, setShowInstructions] = useState(false)


    return (
        <>
            <div className="uiButtons">
                <Button label="Default" onClick={() => { setView(RoomViewEnum.FIRST) }} />
                <Button label="View 1" onClick={() => { setView(RoomViewEnum.SECOND) }} />
                <Button label="View 2" onClick={() => { setView(RoomViewEnum.THIRD) }} />
                <Button label="Left" onClick={() => { movement("moveLeft") }} />
                <Button label="Right" onClick={() => { movement("moveRight") }} />
                <Button label="In" onClick={() => { movement("moveIn") }} />
                <Button label="Out" onClick={() => { movement("moveOut") }} />
            </div>
            {
                showInstructions &&
                <div className="control-instruction" >
                    Mouse Left Click - Rotate <span className="separator"> | </span>
                    Mouse Wheel - Zoom <br />
                    W - Move In<span className="separator"> | </span>
                    A - Move Left<span className="separator"> | </span>
                    S - Move Out<span className="separator"> | </span>
                    D - Move Right
                </div>
            }

            <div className="show-hide-instruction" onMouseEnter={() => setShowInstructions(true)} onMouseLeave={() => setShowInstructions(false)}>
                ?
            </div>
        </>
    )
}