import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import reportWebVitals from './reportWebVitals';
import img1 from "./component/images/image 12 3 (1).png";
import img2 from "./component/images/textures/wood-texture-close.jpg";
import img3 from "./component/images/textures/VanityWhiteWood.jpg";
import img4 from "./component/images/SterlinaIIWall.png";
import img5 from "./component/images/TrendzWall.png";
import img6 from "./component/images/Pine.jpeg";
import img7 from "./component/images/Hickory.jpeg";
import img8 from "./component/images/Elm.jpeg";
import img9 from "./component/images/None.png";
import img10 from "./component/images/SterlinaIIWall01.png";
import img11 from "./component/images/TrendzWall01.png";
import img12 from "./component/images/ShowerAccentRiveria.jpeg";
import img13 from "./component/images/ShowerAccentMedici.jpeg";
import img14 from "./component/images/correct 1.png";
import img15 from "./component/images/luxury-bathroom-visualisation 1.png";
import img16 from "./component/images/image OC-22.png";
import img17 from "./component/images/white-color.webp";
import Woodhickorypineplank from "./component/images/Wood hickory pine plank.web.570.570.avif";
import DaltileAttache from "./component/images/Daltile-Attache.avif";
import EmserCitizen from "./component/images/Emser-Citizen.webp";
import { Canvas } from "@react-three/fiber";
import { Effects } from "./component/Effects";
import { Perf } from "r3f-perf";
import { Experience } from "./component/Experience";
import inputData from "./store/inputData";
import Store from "./store/Store";
import { getKeyDetails } from "./utils/utils";
import { useSnapshot } from "valtio";
import { emitCustomEvent } from "react-custom-events";
import { UIButtons } from "./component/ui/UIButtons";
import Allure from "./component/images/Allure VT31223WHT Vanity Top.avif";
import HardwareResourceElements from "./component/images/Hardware Resource Elements A500-12MB.webp";
import Kohlerunderscore from "./component/images/Kohlerunderscore.webp";
import KohlerClearflo from "./component/images/KohlerClearflo.webp";
import Kohlerveil from "./component/images/Kohlerveil.webp";
import Comode from "./component/images/Comode.webp";
import LavFaucet from "./component/images/LavFaucet.webp";
import LavFaucet2 from "./component/images/LavFaucet2.webp";
import LavFaucet3 from "./component/images/LavFaucet3.webp";
import ShowerHead from "./component/images/ShowerHead.webp";
import ShowerNeck from "./component/images/ShowerNeck.webp";
import ValveTrim from "./component/images/ValveTrim.webp";
import TubSpout from "./component/images/TubSpout.webp";
import Trimforhandheld from "./component/images/Trimforhandheld.webp";
import HandShower from "./component/images/HandShower.webp";
import elbow from "./component/images/Elbow.webp";
import ShowerGlassDoor from "./component/images/ShowerGlassDoor.webp";
import TPHolder from "./component/images/TPHolder.webp";
import TowelBar from "./component/images/TowelBar.webp";
import TowelRing from "./component/images/TowelRing.webp";
import RobeHook from "./component/images/RobeHook.webp";
import mirror from "./component/images/Mirror.webp";
import Medicinecabinet from "./component/images/Medicinecabinet.webp";
import CeilingPaintColor from "./component/images/Untitled-1.png";
import MSICalacattaMarbella from "./component/images/MSI Calacatta Marbella.jpg";
import MSISavoy from "./component/images/MSI Savoy.jpg";
import MSISande from "./component/images/MSI Sande.jpg";
import MSILuxorRoyallink from "./component/images/MSI Luxor Royal link.jpg";
import MSIHarlowChevron from "./component/images/MSI Harlow Chevron.jpg";
import Cabinet from "./component/images/Cabinet.jpg";
import tub1 from "./component/images/tub1.webp";
import Comode1 from "./component/images/Comode1.webp";
import ComodeSeat1 from "./component/images/Comode Seat1.webp";
import LavFaucet1 from "./component/images/Lav Faucet1.webp";
import LavFaucet5 from "./component/images/Lav Faucet5.webp";
import LavFaucet6 from "./component/images/Lav Faucet6.webp";
import ShowerHeadTrimKit from "./component/images/ShowerHead,Trim Kit.webp";
import TubSpout1 from "./component/images/Tub Spout1.webp";
import Trimforhandheld1 from "./component/images/Trim for hand held1.webp";
import HandShower1 from "./component/images/Hand Shower 1.webp";
import elbow1 from "./component/images/Elbow1.webp";
import ShowerGlassDoor1 from "./component/images/ShowerGlassDoor1.webp";
import TPHolder1 from "./component/images/TP Holder1.webp";
import TowelBar1 from "./component/images/Towel Bar1.webp";
import TowelRing1 from "./component/images/Towel Bar1.webp";
import RobeHook1 from "./component/images/Robe Hook1.webp";
import mirror1 from "./component/images/Mirror1.webp";
import Medicinecabinet1 from "./component/images/Medicine cabinet1.webp";
import VanitySconce1 from "./component/images/Vanity Sconce1.webp";
import VanityOverhead1 from "./component/images/Vanity Overhead1.webp";
import FloorWallTile from "./component/images/FloorWall Tile1.avif";
import EmserHeksa2 from "./component/images/Emser - Heksa2.webp";
import EmserRhythm2 from "./component/images/Emser - Rhythm2.webp";
import TilebarPenny2 from "./component/images/Tilebar - Penny2.avif";
import Sink2 from "./component/images/Sink2.webp";
import EmserRaku from "./component/images/Emser - Raku matte2.jpg";
import Tub2 from "./component/images/Tub2.webp";
import TubOverflow2 from "./component/images/Tub Overflow2.webp";
import Comode2 from "./component/images/Comode2.webp";
import ComodeSeat2 from "./component/images/Comode Seat2.webp";
import LavFaucet7 from "./component/images/Lav Faucet7.webp";
import LavFaucet8 from "./component/images/LavFaucet8.webp";
import LavFaucet9 from "./component/images/LavFaucet9.webp";
import LavDrain2 from "./component/images/Lav Drain2.webp";
import ShowerHeadwithTubSpoutTrimSet2 from "./component/images/ShowerHeadwithTubSpoutTrimSet2.webp";
import ShowerOnlywithTrim2 from "./component/images/Shower Only with Trim2.webp";
import TransferTrim2 from "./component/images/Transfer Trim2.webp";
import HandShower2 from "./component/images/Hand Shower 2.webp";
import TPHolde2 from "./component/images/TP Holder2.webp";
import TowelBar2 from "./component/images/Towel Bar2.webp";
import TowelRing2 from "./component/images/Towel Ring2.webp";
import RobeHook2 from "./component/images/Robe Hook2.webp";
import mirror2 from "./component/images/Mirror2.webp";
import Medicinecabinet2 from "./component/images/Medicine Cabinet2.webp";
import VanitySconce2 from "./component/images/Vanity Sconce2.webp";
import VanityOverhead2 from "./component/images/Vanity Overhead2.webp";
import LightFixture2 from "./component/images/Light Fixture2.webp";
import MSIEdenStatuary3 from "./component/images/MSIEdenStatuary3.jpg";
import MSIKaya3 from "./component/images/MSI - Kaya3.jpg";
import MSIRagallo3 from "./component/images/MSI - Ragallo3.jpg";
import MSIRagalloo3 from "./component/images/MSI - Ragalloo3.jpg";
import MSIEdenStatuaryy3 from "./component/images/MSI - Eden Statuary3.jpg";
import MSIHexagono3 from "./component/images/MSI - Hexagono3.jpg";
import MSIEdenStatuaryhex3 from "./component/images/MSI - Eden Statuary hex3.jpg";
import MSIinchblackhex3 from "./component/images/MSI - 2 inch black hex3.jpg";
import VanityALL3 from "./component/images/Vanity ALL3.webp";
import Tub3 from "./component/images/Tub3.webp";
import TubOverflow3 from "./component/images/Tub3.webp";
import Comode3 from "./component/images/Comode3.webp";
import ComodeSeat3 from "./component/images/Comode Seat3.webp";
import LavFaucet10 from "./component/images/Lav Faucet10.webp";
import LavFaucet11 from "./component/images/Lav Faucet11.webp";
import LavDrain3 from "./component/images/Lav Drain3.webp";
import ShowerHead3 from "./component/images/Shower Head3.webp";
import ShowerArm3 from "./component/images/Shower Arm3.webp";
import Trim3 from "./component/images/Trim3.webp";
import TransferTrim3 from "./component/images/Transfer Trim3.webp";
import TubSpout3 from "./component/images/Tub Spout3.webp";
import HandShower3 from "./component/images/Hand Shower 3.webp";
import ShowerBar3 from "./component/images/Shower Bar3.webp";
import Elbow3 from "./component/images/Elbow3.webp";
import ShowerGlassDoor3 from "./component/images/ShowerGlassDoor3.webp";
import TPHolde3 from "./component/images/TP Holder3.webp";
import TowelBar3 from "./component/images/Towel Bar3.webp";
import TowelRing3 from "./component/images/Towel Ring3.webp";
import RobeHook3 from "./component/images/Robe Hook3.webp";
import mirror3 from "./component/images/Mirror3.webp";
import Medicinecabinet3 from "./component/images/Medicine cabinet3.webp";
import VanitySconce3 from "./component/images/Vanity Sconce3.webp";
import LightFixture3 from "./component/images/Light Fixture3.jpg";

const OptionBox = ({ id, title, checked, details, onChange }) => (
  <div className="optionbox">
    <div className={`modalimgshape ${id}`}>
      <p
        className="electricaltitle"
        style={{ color: checked ? "#F8A11C" : "#000" }}
      >
        {title}
      </p>
      <input
        type="checkbox"
        className="checkimg"
        checked={checked}
        onChange={onChange}
      />
      <p className="electricaldetail">{details}</p>
    </div>
  </div>
);

const ColorCircle = ({ id, src, title, onClick }) => (
  <div className="colorcircle" onClick={onClick}>
    <div className="circle">
      <img
        id={id}
        style={{ width: "70px", height: "70px", borderRadius: "50px" }}
        src={src}
        alt={title}
      />
    </div>
    <p className="subcircletitle">{title}</p>
  </div>
);

const TogleFic = ({ title, checked, onChange }) => (
  <div className="toglefic">
    <span className="togletitle">{title}</span>
    <input
      className="togle"
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
  </div>
);

const AccessoriesButton = ({ title, checked, onChange }) => (
  <div className="accessoriesbtn">
    <p className="accessoriesbtntitle">{title}</p>
    <img className="accessoriescheck" src={img14} alt="Check" />
    {/* <input type="" className="checkimg" checked={checked} onChange={onChange} /> */}
  </div>
);

const App = () => {
  const [spaCheckedOptions, setSpaCheckedOptions] = useState({
    elecpkg1: true,
    elecpkg2: false,
    plumbingPkg1: true,
    plumbingPkg2: false,
    plumbingPkg3: false,
    upgradeWallMount: false,
    medicineCabinet: false,
    heatedSeatBidet: false,
    heatedFlooring: false,
    lightOak: true,
    whiteWood: false,
    floorSterlina: true,
    floorTrendz: false,
    wallSterlina: true,
    wallTrendz: false,
    convertTubShower: false,
    upgradeHandheld: false,
    upgradeHandheldOverhead: false,
  });

  const [contemporaryCheckedOptions, setContemporaryCheckedOptions] = useState({
    wallPaint: false, // done
    ceilingPaintColor: false, // done
    toiletPaperHolder: null, // done
    towelBar: false, // done
    towelRing: false, // done
    robeHook: false, // done
    mirror: false, // done
    medicineCabinet: null, // done

    emserCitizen: true, // done

    sterlina: true, // done

    woodHickoryPinePlank: true, // done

    hardwareResourceElements: false, // done
    allureVT31223WHTVanityTop: false, // done
    allure: false, // done

    tub: false, // done
    tubDrain: false, // done
    comode: false, // done
    comodeSeat: null, // done
    lavFaucetWallMount: false, // done
    lavFaucetDeckMount: false, // done
    lavFaucet: false, // done
    showerHead: null,
    showerNeck: null,
    valveTrim: null,
    tubSpout: null, // done
    trimForHandheld: null,
    handShower: null,
    elbow: null,
    showerGlassDoor: false, // done
  });

  const [glamoureCheckedOptions, setGlamoureCheckedOptions] = useState({
    wallPaint: false, // done
    ceilingPaintColor: false, // done

    toiletPaperHolder: false, // done
    towelBar: false, // done
    towelRing: false, // done
    robeHook: false, // done
    mirror: false, // done
    medicineCabinet: false, // done

    msiSande: true, // done

    msiCalacattaMarbella: false, // done
    msiSavoy: true, // done

    msiLuxorRoyalLink: true, // done
    msiHarlowChevron: false, // done

    cabinet: false, // done

    tub: false, // done
    tubDrain: false, // done
    comode: false, // done
    comodeSeat: false, // done
    lavFaucetWallMount: false, // done
    lavFaucetDeckMount: false, // done
    lavFaucet: false, // done
    showerHeadTrimKit: false,
    tubSpout: false, // done
    trimForHandheld: false,
    handShower: false,
    elbow: false,
    showerGlassDoor: false, // done

    vanitySconce: false, // done
    vanityOverhead: false, // done
  });

  const [urbanCheckedOptions, setUrbanCheckedOptions] = useState({
    wallPaint: false, // no purpose found
    ceilingPaintColor: false, // no purpose found

    toiletPaperHolder: false, // done
    towelBar: false, // done
    towelRing: false, // done
    robeHook: false, // done
    mirror: false, // done
    medicineCabinet: false, // done

    daltileMemoir: true, // no purpose found

    emserHeksa: false, // done || emserHeksa material not found in materials
    emserRythm: true, // done

    tilebarPenny: false, // done || not found in materials
    emserRakuMatte: false, // done || not found in materials

    sink: null, // done || sink model not seperate hence not changeable

    tub: false, // done
    tubOverflow: null, // done || tubOverflow model not seperate hence not changeable
    comode: false, // done
    comodeSeat: null, // done || comodeSeat model not seperate hence not changeable
    lavFaucetWallMount: null, // done || is not included
    lavFaucetDeckMount: false, // done
    lavFaucet: false, // done
    lavDrain: false, // done || is not included
    showerHeadWithTubSpoutTrimSet: true, // done
    showerOnlyWithTrim: null, // done || not found
    transferTrim: false, // done
    handShower: false, // done

    vanitySconce: true, // done
    vanityOverhead: true, // done
    lightFixture: true, // done
  });

  const [vintageCheckedOptions, setVintageCheckedOptions] = useState({
    wallPaint: false, // no purpose found
    ceilingPaintColor: false, // no purpose found

    toiletPaperHolder: null, // done
    towelBar: false, // done
    towelRing: false, // done
    robeHook: false, // done
    mirror: false, // done
    medicineCabinet: false, // done

    msiRagallo: false, // missing
    msiEdenStatuary: false, // missing
    msiHexagono: true, // missing
    msiEdenStatuaryHex: false, // missing
    msi2InchBlackHex: false, // missing

    msiEdenStatuaryWall: true, // missing
    msiKayaWall: false, // missing
    msiRagalloWall: false, // missing

    vanityAll: false, // confusion

    tub: false, // done
    tubOverflow: false, // done || tubOverflow model not seperate hence not changeable
    comode: false, // done
    comodeSeat: false, // done || comodeSeat model not seperate hence not changeable
    lavFaucetDeckMount: false, // done
    lavFaucet: false, // done
    lavDrain: false, // done
    showerHead: false, // done
    showerArm: false, // done
    trim: false, // done
    transferTrim: false, // done
    tubSpout: false, // done
    handShower: false, // done
    showerBar: false, // done
    elbow: false, // done
    showerGlassDoor: false, // done

    vanitySconce: false, // done
    lightFixture: false, // done
  });

  const styleCheckedOptions = {
    spa: spaCheckedOptions,
    contemporary: contemporaryCheckedOptions,
    glamoure: glamoureCheckedOptions,
    urban: urbanCheckedOptions,
    vintage: vintageCheckedOptions,
  };

  const [selectedModel, setSelectedModel] = useState("SPA");
  const { wKey, aKey, sKey, dKey } = getKeyDetails();
  const snap = useSnapshot(Store);
  const { loadingState } = snap;

  useEffect(() => {
    wKey.addEventListener("holding", () => {
      emitCustomEvent("moveIn");
    });
    aKey.addEventListener("holding", () => {
      emitCustomEvent("moveLeft");
      console.log("A is being held");
    });
    sKey.addEventListener("holding", () => {
      emitCustomEvent("moveOut");
    });
    dKey.addEventListener("holding", () => {
      emitCustomEvent("moveRight");
    });
    return () => {
      wKey.removeEventListener("holding");
      aKey.removeEventListener("holding");
      sKey.removeEventListener("holding");
      dKey.removeEventListener("holding");
    };
  }, []);

  const handleToggle = (name) => (event) => {
    setSpaCheckedOptions({
      ...spaCheckedOptions,
      [name]: event.target.checked,
    });
  };

  const availableOptions = inputData.bathroom_type;

  const changeBathroomType = (name) => {
    Store.variant = name;
    setSelectedModel(name);
  };
  useEffect(() => {
    Store.loadingState = true;
  }, [snap.variant]);

  const handleSubmit = async () => {
    try {
      const response = await fetch("http://127.0.0.1:8000/api/bathroom-users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          contact_number: formData.contact_number,
          price: formData.price,
        }),
        redirect: "manual",
      });

      if (response.status === 302) {
        const redirectUrl = response.headers.get("Location");
        console.log("Redirecting to:", redirectUrl);

        return;
      }

      if (response.ok) {
        const result = await response.json();
        console.log("Form submitted successfully:", result);
      } else {
        const text = await response.text(); // Read as text if not JSON
        console.error("Error submitting form:", text);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact_number: "",
    price: "50000",
  });

  return (
    <div>
      <>
        {/* {loadingState && (
          <div className="loader">
            <img src={`/loader.gif`} alt="" />
            <div className="control-instruction-loading">
              Mouse Left Click - Rotate <span className="separator"> | </span>
              Mouse Wheel - Zoom <br />W - Move In
              <span className="separator"> | </span>A - Move Left
              <span className="separator"> | </span>S - Move Out
              <span className="separator"> | </span>D - Move Right
            </div>
          </div>
        )} */}
      </>
      <div className="model-view">
        <div className="model-graphic" id="your-div-id">
          <div className="header">
            <h3 className="title">xcēd</h3>
            <p className="pagename">Rectangle | Layout 1</p>
          </div>
          <Canvas
            className="main-image"
            shadows={true}
            camera={{ position: [0, 0, 1e-5], fov: 70, far: 1000, near: 0.1 }}
            gl={{ toneMappingExposure: 0.7, antialias: true }}
          >
            <Effects />
            {process.env.NODE_ENV === "development" && (
              <Perf trackGPU={true} position="bottom-left" />
            )}
            <Experience styleCheckedOptions={styleCheckedOptions} />
          </Canvas>
          {/* <img className="main-image" src={img15} alt="Option 1" /> */}
        </div>
        <UIButtons />

        <div id="lastside" className="model-direction">
          <div className="side-bar-Menu" id="sidebarscroll">
            <div className="pricebox">
              PRICE: <span className="boxspan">$50,000</span>
            </div>
            <h3 className="modeltitle">Bathroom Model</h3>

            {availableOptions.map((option, index) => (
              <>
                <div
                  key={index}
                  className="optionbox"
                  onClick={() => changeBathroomType(option.name)}
                >
                  <img className="modalimg" src={img1} alt={option.name} />
                  <p className="Optiondesign">
                    {"OPTION" + (index + 1)}
                    <br />
                    <span className="optionspan">{option.name}</span>
                  </p>
                </div>
              </>
            ))}

            {/* Conditional rendering based on selected model */}
            <div className="model-details">
              {selectedModel === "SPA" && (
                <div>
                  <h3 className="modeltitle">Vanity</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="vanityLightOak"
                      src={img2}
                      title="Light Oak"
                      onClick={() =>
                        setSpaCheckedOptions((prev) => ({
                          ...prev,
                          lightOak: true,
                          whiteWood: false,
                        }))
                      }
                    />
                    <ColorCircle
                      id="vanityWhiteWood"
                      src={img3}
                      title="White wood"
                      onClick={() =>
                        setSpaCheckedOptions((prev) => ({
                          ...prev,
                          lightOak: false,
                          whiteWood: true,
                        }))
                      }
                    />
                  </div>

                  <h5 className="subVanitytitle">Accessories</h5>
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                      marginLeft: "12%",
                      paddingRight: "15px",
                    }}
                  >
                    <AccessoriesButton
                      title="Accessories"
                      checked={spaCheckedOptions.accessories}
                      onChange={handleToggle("accessories")}
                    />
                    <AccessoriesButton
                      title="Mirror"
                      checked={spaCheckedOptions.mirror}
                      onChange={handleToggle("mirror")}
                    />
                  </div>

                  <TogleFic
                    title="I want medicine cabinet"
                    checked={spaCheckedOptions.medicineCabinet}
                    onChange={handleToggle("medicineCabinet")}
                  />
                  <div>
                    <h5 className="subVanitytitle">Electrical</h5>
                    <div className="optionbox">
                      <div
                        style={{ border: "1px solid #F8A11C" }}
                        className="modalimgshape"
                        id="elecpkg1"
                      >
                        <p
                          style={{ color: "#F8A11C" }}
                          className="electricaltitle"
                          id="elecpkg1title"
                        >
                          Package 1
                        </p>
                        <input
                          type="checkbox"
                          className="checkimg"
                          id="electricalpkg1"
                          checked={spaCheckedOptions.elecpkg1}
                          onChange={() =>
                            setSpaCheckedOptions((prev) => ({
                              ...prev,
                              elecpkg1: true,
                              elecpkg2: false,
                            }))
                          }
                        />
                        <p className="electricaldetail">
                          Above mirror, Center fixture, Dimmable switching,
                          Exhaust fan (if app.)
                        </p>
                      </div>
                    </div>
                    <div className="optionbox">
                      <div className="modalimgshape" id="elecpkg2">
                        <p className="electricaltitle" id="elecpkg2title">
                          Package 2
                        </p>
                        <input
                          type="checkbox"
                          className="checkimg"
                          id="electricalpkg2"
                          checked={spaCheckedOptions.elecpkg2}
                          onChange={() =>
                            setSpaCheckedOptions((prev) => ({
                              ...prev,
                              elecpkg1: false,
                              elecpkg2: true,
                            }))
                          }
                        />
                        <p className="electricaldetail">
                          Sconces, Can lights (if app.), Dimmable switching,
                          Exhaust fan (if app.)
                        </p>
                      </div>
                    </div>
                  </div>
                  <TogleFic
                    title="Heated seat, bidet combo"
                    checked={spaCheckedOptions.heatedSeatBidet}
                    onChange={handleToggle("heatedSeatBidet")}
                  />
                  <TogleFic
                    title="Heated flooring"
                    checked={spaCheckedOptions.heatedFlooring}
                    onChange={handleToggle("heatedFlooring")}
                  />

                  <h3 className="modeltitle">Tiles</h3>
                  <h5 className="subVanitytitle">FLOOR TILE</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="image1"
                      src={img4}
                      title="Sterlina"
                      onClick={() =>
                        setSpaCheckedOptions((prev) => ({
                          ...prev,
                          floorSterlina: true,
                          floorTrendz: false,
                        }))
                      }
                    />
                    <ColorCircle
                      id="image2"
                      src={img5}
                      title="Trendz"
                      onClick={() =>
                        setSpaCheckedOptions((prev) => ({
                          ...prev,
                          floorSterlina: false,
                          floorTrendz: true,
                        }))
                      }
                    />
                  </div>

                  <h5 className="subVanitytitle">Floor Accent Tile</h5>
                  <div className="maincirlediv">
                    <ColorCircle id="floorPine" src={img6} title="Pine" />
                    <ColorCircle id="floorHickory" src={img7} title="Hickory" />
                    <ColorCircle id="floorElm" src={img8} title="Elm" />
                    <ColorCircle id="floorNone" src={img9} title="None" />
                  </div>

                  <h5 className="subVanitytitle">WALL TILE</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="wall1"
                      src={img10}
                      title="Sterlina"
                      onClick={() =>
                        setSpaCheckedOptions((prev) => ({
                          ...prev,
                          wallSterlina: true,
                          wallTrendz: false,
                        }))
                      }
                    />
                    <ColorCircle
                      id="wall2"
                      src={img11}
                      title="Trendz"
                      onClick={() =>
                        setSpaCheckedOptions((prev) => ({
                          ...prev,
                          wallSterlina: false,
                          wallTrendz: true,
                        }))
                      }
                    />
                  </div>

                  <h5 className="subVanitytitle">Wall Accent Tile</h5>
                  <div className="maincirlediv">
                    <ColorCircle id="WallPine" src={img6} title="Pine" />
                    <ColorCircle id="WallHickory" src={img7} title="Hickory" />
                    <ColorCircle id="WallElm" src={img8} title="Elm" />
                    <ColorCircle id="WallNone" src={img9} title="None" />
                  </div>

                  <div id="colorCircleContainer" className="maincirlediv"></div>

                  <h5
                    className="subVanitytitle"
                    id="showerAccentHeading"
                    style={{ display: "none" }}
                  >
                    Shower Accent Tile
                  </h5>
                  <div
                    className="maincirlediv"
                    id="showerAccentTiles"
                    style={{ display: "none" }}
                  >
                    <ColorCircle
                      id="showerRiveria"
                      src={img12}
                      title="Riveria"
                    />
                    <ColorCircle id="showerMedici" src={img13} title="Medici" />
                  </div>
                  <h3 className="modeltitle">Plumbing</h3>
                  <OptionBox
                    id="plumbingpkg1main"
                    title="Package 1"
                    checked={spaCheckedOptions.plumbingPkg1}
                    details="Tub, Toilet, Shower Fixtures, Lav and Faucet combo"
                    onChange={handleToggle("plumbingPkg1")}
                  />
                  <OptionBox
                    id="plumbingpkg2main"
                    title="Package 2"
                    checked={spaCheckedOptions.plumbingPkg2}
                    details="Shower pan base with glass door and panel, Toilet, Shower Fixtures, Lav and Faucet combo"
                    onChange={handleToggle("plumbingPkg2")}
                  />
                  <OptionBox
                    id="plumbingpkg3main"
                    title="Package 3"
                    checked={spaCheckedOptions.plumbingPkg3}
                    details="Shower tile base with glass door, Floor Tile, Accent Tile, Toilet, Shower Fixtures, Lav and Faucet combo"
                    onChange={handleToggle("plumbingPkg3")}
                  />

                  <h3 className="modeltitle">Plumbing upgrades</h3>
                  <TogleFic
                    title="Convert existing tub to shower or vice versa"
                    checked={spaCheckedOptions.convertTubShower}
                    onChange={handleToggle("convertTubShower")}
                  />
                  <TogleFic
                    title="Upgrade to shower handheld"
                    checked={spaCheckedOptions.upgradeHandheld}
                    onChange={handleToggle("upgradeHandheld")}
                  />
                  <TogleFic
                    title="Upgrade to handheld and overhead"
                    checked={spaCheckedOptions.upgradeHandheldOverhead}
                    onChange={handleToggle("upgradeHandheldOverhead")}
                  />
                  <TogleFic
                    title="Upgrade wall mount faucet"
                    checked={spaCheckedOptions.upgradeWallMount}
                    onChange={handleToggle("upgradeWallMount")}
                  />
                  <TogleFic
                    title="Add half panel glass to tub"
                    checked={spaCheckedOptions.addHalfPanel}
                    onChange={handleToggle("addHalfPanel")}
                  />
                  <div className="form-container">
                    <div className="input-field">
                      <label htmlFor="name" className="input-label">
                        Name:
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter your name"
                        className="input-box"
                        required
                      />
                    </div>

                    <div className="input-field">
                      <label htmlFor="email" className="input-label">
                        Email:
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter your email"
                        className="input-box"
                        required
                      />
                    </div>

                    <div className="input-field">
                      <label htmlFor="contact_number" className="input-label">
                        Contact Number:
                      </label>
                      <input
                        type="tel"
                        id="contact_number"
                        name="contact_number"
                        value={formData.contact_number}
                        onChange={handleChange}
                        placeholder="Enter your contact number"
                        className="input-box"
                        required
                      />
                    </div>

                    <div className="input-field">
                      <label htmlFor="price" className="input-label">
                        Price:
                      </label>
                      <input
                        type="text"
                        id="price"
                        name="price"
                        className="input-box"
                        value={formData.price}
                        readOnly
                      />
                    </div>

                    {/* <button type="submit" className="submit-button" onClick={handleSubmit}>
                      Submit
                    </button> */}
                  </div>

                  <div className="pricebox1">SAVE FOR LATER</div>
                  <div className="pricebox2" onClick={handleSubmit}>
                    COMPLETE
                  </div>
                  <div className="pricebox">
                    PRICE: <span className="boxspan">$50,000</span>
                  </div>
                </div>
              )}
              {selectedModel === "Contemporary" && (
                <div>
                  {/* Paint Section */}
                  <h3 className="modeltitle">Paint</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="vanityLightOak"
                      src={img16}
                      title="Wall Paint"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          wallPaint: true,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="vanityWhiteWood"
                      src={CeilingPaintColor}
                      title="Ceiling Paint Color"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          ceilingPaintColor: true,
                        }));
                      }}
                    />
                    <ColorCircle id="floorNone" src={img9} title="None" />
                  </div>

                  {/* Accessories Section */}
                  <h5 className="modeltitle">Accessories</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      src={TPHolder}
                      title="TP Holder"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          toiletPaperHolder: !prev.toiletPaperHolder,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={TowelBar}
                      title="Towel Bar"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          towelBar: !prev.towelBar,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={TowelRing}
                      title="Towel Ring"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          towelRing: !prev.towelRing,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={RobeHook}
                      title="Robe Hook"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          robeHook: !prev.robeHook,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={mirror}
                      title="Mirror"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          mirror: !prev.mirror,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={Medicinecabinet}
                      title="Medicine Cabinet"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          medicineCabinet: !prev.medicineCabinet,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          mirror: false,
                          robeHook: false,
                          towelRing: false,
                          towelBar: false,
                          toiletPaperHolder: false,
                          medicineCabinet: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Tiles Section */}
                  <h3 className="modeltitle">Tiles</h3>

                  {/* Floor Tiles */}
                  <h5 className="subVanitytitle">Floor Tile</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="image1"
                      src={EmserCitizen}
                      title="Emser Citizen"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          emserCitizen: !prev.emserCitizen,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="floorNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          emserCitizen: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Wall Tiles */}
                  <h5 className="subVanitytitle">Wall Tile</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="wall1"
                      src={DaltileAttache}
                      title="Sterlina"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          sterlina: !prev.sterlina,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="floorNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          sterlina: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Wall Accent Tiles */}
                  <h5 className="subVanitytitle">Wall Accent Tile</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="WallPine"
                      src={Woodhickorypineplank}
                      title="Wood Hickory Pine Plank"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          woodHickoryPinePlank: !prev.woodHickoryPinePlank,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          woodHickoryPinePlank: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Vanity Section */}
                  <h3 className="modeltitle">Vanity</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="WallPine"
                      src={Allure}
                      title="Allure VT31223WHT Vanity Top"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          allureVT31223WHTVanityTop:
                            !prev.allureVT31223WHTVanityTop,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallHickory"
                      src={Allure}
                      title="Allure"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          allure: !prev.allure,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallElm"
                      src={HardwareResourceElements}
                      title="Hardware Resource Elements"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          hardwareResourceElements:
                            !prev.hardwareResourceElements,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          allureVT31223WHTVanityTop: false,
                          allure: false,
                          hardwareResourceElements: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Plumbing Section */}
                  <h3 className="modeltitle">Plumbing</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      src={Kohlerunderscore}
                      title="Tub"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          tub: !prev.tub,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={KohlerClearflo}
                      title="Tub Drain"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          tubDrain: !prev.tubDrain,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={Comode}
                      title="Comode"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          comode: !prev.comode,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={Kohlerveil}
                      title="Comode Seat"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          comodeSeat: !prev.comodeSeat,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={LavFaucet3}
                      title="Lav Faucet"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          lavFaucetWallMount: !prev.lavFaucetWallMount,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={LavFaucet}
                      title="Lav Faucet"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          lavFaucetDeckMount: !prev.lavFaucetDeckMount,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={LavFaucet2}
                      title="Lav Faucet"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          lavFaucet: !prev.lavFaucet,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={ShowerHead}
                      title="Shower Head"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          showerHead: !prev.showerHead,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={ShowerNeck}
                      title="Shower Neck"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          showerNeck: !prev.showerNeck,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={ValveTrim}
                      title="Valve Trim"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          valveTrim: !prev.valveTrim,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={TubSpout}
                      title="Tub Spout"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          tubSpout: !prev.tubSpout,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={Trimforhandheld}
                      title="Trim for Hand Held"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          trimForHandheld: !prev.trimForHandheld,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={HandShower}
                      title="Hand Shower"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          handShower: !prev.handShower,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={elbow}
                      title="Elbow"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          elbow: !prev.elbow,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={ShowerGlassDoor}
                      title="Shower Glass Door"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          showerGlassDoor: !prev.showerGlassDoor,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setContemporaryCheckedOptions((prev) => ({
                          ...prev,
                          tub: false,
                          tubDrain: false,
                          comode: false,
                          comodeSeat: false,
                          lavFaucetWallMount: false,
                          lavFaucetDeckMount: false,
                          lavFaucet: false,
                          showerHead: false,
                          showerNeck: false,
                          valveTrim: false,
                          tubSpout: false,
                          trimForHandheld: false,
                          handShower: false,
                          elbow: false,
                          showerGlassDoor: false,
                        }));
                      }}
                    />
                  </div>
                </div>
              )}
              {selectedModel === "Glamoure" && (
                <div>
                  {/* Paint Section */}
                  <h3 className="modeltitle">Paint</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="vanityLightOak"
                      src={img16}
                      title="Wall Paint"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          wallPaint: !prev.wallPaint,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="vanityWhiteWood"
                      src={CeilingPaintColor}
                      title="Ceiling Paint Color"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          ceilingPaintColor: !prev.ceilingPaintColor,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="floorNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          ceilingPaintColor: false,
                          wallPaint: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Accessories Section */}
                  <h5 className="modeltitle">Accessories</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      src={TPHolder1}
                      title="TP Holder"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          toiletPaperHolder: !prev.toiletPaperHolder,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={TowelBar1}
                      title="Towel Bar"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          towelBar: !prev.towelBar,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={TowelRing1}
                      title="Towel Ring"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          towelRing: !prev.towelRing,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={RobeHook1}
                      title="Robe Hook"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          robeHook: !prev.robeHook,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={mirror1}
                      title="Mirror"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          mirror: !prev.mirror,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={Medicinecabinet1}
                      title="Medicine Cabinet"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          medicineCabinet: !prev.medicineCabinet,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          towelBar: false,
                          towelRing: false,
                          robeHook: false,
                          toiletPaperHolder: false,
                          mirror: false,
                          medicineCabinet: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Tiles Section */}
                  <h3 className="modeltitle">Tiles</h3>

                  {/* Floor Tiles */}
                  <h5 className="subVanitytitle">Floor Tile</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="image1"
                      src={MSISande}
                      title="MSI Sande"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          msiSande: !prev.msiSande,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="floorNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          msiSande: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Wall Tiles */}
                  <h5 className="subVanitytitle">Wall Tile</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="wall1"
                      src={MSICalacattaMarbella}
                      title="MSI Calacatta Marbella"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          msiCalacattaMarbella: !prev.msiCalacattaMarbella,
                          msiSavoy: false,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="wall1"
                      src={MSISavoy}
                      title="MSI Savoy"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          msiSavoy: !prev.msiSavoy,
                          msiCalacattaMarbella: false,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="floorNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          msiCalacattaMarbella: false,
                          msiSavoy: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Wall Accent Tiles */}
                  <h5 className="subVanitytitle">Wall Accent Tile</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="WallPine"
                      src={MSILuxorRoyallink}
                      title="MSI Luxor Royal link"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          msiLuxorRoyalLink: !prev.msiLuxorRoyalLink,
                          msiHarlowChevron: false,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallPine"
                      src={MSIHarlowChevron}
                      title="MSI Harlow Chevron"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          msiHarlowChevron: !prev.msiHarlowChevron,
                          msiLuxorRoyalLink: false,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          msiLuxorRoyalLink: false,
                          msiHarlowChevron: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Vanity Section */}
                  <h3 className="modeltitle">Vanity</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="WallPine"
                      src={Cabinet}
                      title="Cabinet"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          cabinet: !prev.cabinet,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          cabinet: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Plumbing Section */}
                  <h3 className="modeltitle">Plumbing</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      src={tub1}
                      title="Tub"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          tub: !prev.tub,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={Comode1}
                      title="Comode"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          comode: !prev.comode,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={ComodeSeat1}
                      title="Comode Seat"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          comodeSeat: !prev.comodeSeat,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={LavFaucet1}
                      title="Lav Faucet"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          lavFaucetDeckMount: !prev.lavFaucetDeckMount,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={LavFaucet5}
                      title="Lav Faucet"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          lavFaucet: !prev.lavFaucet,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={LavFaucet6}
                      title="Lav Faucet"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          lavFaucetWallMount: !prev.lavFaucetWallMount,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={ShowerHeadTrimKit}
                      title="Shower Head, Trim Kit"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          showerHeadTrimKit: !prev.showerHeadTrimKit,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={TubSpout1}
                      title="Tub Spout"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          tubSpout: !prev.tubSpout,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={Trimforhandheld1}
                      title="Trim for Hand Held"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          trimForHandheld: !prev.trimForHandheld,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={HandShower1}
                      title="Hand Shower"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          handShower: !prev.handShower,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={elbow1}
                      title="Elbow"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          elbow: !prev.elbow,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={ShowerGlassDoor1}
                      title="Shower Glass Door"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          showerGlassDoor: !prev.showerGlassDoor,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          tub: false,
                          comode: false,
                          comodeSeat: false,
                          lavFaucetDeckMount: false,
                          lavFaucet: false,
                          lavFaucetWallMount: false,
                          showerHeadTrimKit: false,
                          tubSpout: false,
                          trimForHandheld: false,
                          handShower: false,
                          elbow: false,
                          showerGlassDoor: false,
                        }));
                      }}
                    />
                  </div>

                  <h3 className="modeltitle">Lighting</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="WallPine"
                      src={VanitySconce1}
                      title="Vanity Sconce"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          vanitySconce: !prev.vanitySconce,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallPine"
                      src={VanityOverhead1}
                      title="Vanity Overhead"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          vanityOverhead: !prev.vanityOverhead,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setGlamoureCheckedOptions((prev) => ({
                          ...prev,
                          vanityOverhead: false,
                          vanitySconce: false,
                        }));
                      }}
                    />
                  </div>
                </div>
              )}
              {selectedModel === "Urban" && (
                <div>
                  {/* Paint Section */}
                  <h3 className="modeltitle">Paint</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="vanityLightOak"
                      src={img16}
                      title="Wall Paint"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          wallPaint: !prev.wallPaint,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="vanityWhiteWood"
                      src={CeilingPaintColor}
                      title="Ceiling Paint Color"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          ceilingPaintColor: !prev.ceilingPaintColor,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="floorNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          wallPaint: false,
                          ceilingPaintColor: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Accessories Section */}
                  <h5 className="modeltitle">Accessories</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      src={TPHolde2}
                      title="TP Holder"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          toiletPaperHolder: !prev.toiletPaperHolder,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={TowelBar2}
                      title="Towel Bar"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          towelBar: !prev.towelBar,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={TowelRing2}
                      title="Towel Ring"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          towelRing: !prev.towelRing,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={RobeHook2}
                      title="Robe Hook"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          robeHook: !prev.robeHook,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={mirror2}
                      title="Mirror"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          mirror: !prev.mirror,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={Medicinecabinet2}
                      title="Medicine Cabinet"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          medicineCabinet: !prev.medicineCabinet,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          towelBar: false,
                          towelRing: false,
                          robeHook: false,
                          toiletPaperHolder: false,
                          mirror: false,
                          medicineCabinet: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Tiles Section */}
                  <h3 className="modeltitle">Tiles</h3>

                  {/* Floor Tiles */}
                  <h5 className="subVanitytitle">Floor Tile</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="image1"
                      src={FloorWallTile}
                      title="Daltile Memoir"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          daltileMemoir: !prev.daltileMemoir,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="floorNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          daltileMemoir: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Wall Tiles */}
                  <h5 className="subVanitytitle">Wall Tile</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="wall1"
                      src={EmserHeksa2}
                      title="Emser - Heksa"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          emserHeksa: !prev.emserHeksa,
                          emserRythm: false,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="wall1"
                      src={EmserRhythm2}
                      title="Emser - Rhythm"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          emserRythm: !prev.emserRythm,
                          emserHeksa: false,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="floorNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          emserRythm: false,
                          emserHeksa: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Wall Accent Tiles */}
                  <h5 className="subVanitytitle">Wall Accent Tile</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="WallPine"
                      src={TilebarPenny2}
                      title="Tilebar - Penny"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          tilebarPenny: !prev.tilebarPenny,
                          emserRakuMatte: false,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallPine"
                      src={EmserRaku}
                      title="Emser - Raku matte"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          emserRakuMatte: !prev.emserRakuMatte,
                          tilebarPenny: false,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          emserRakuMatte: false,
                          tilebarPenny: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Vanity Section */}
                  <h3 className="modeltitle">Vanity</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="WallPine"
                      src={Sink2}
                      title="Sink"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          sink: !prev.sink,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={Cabinet}
                      title="None"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          sink: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Plumbing Section */}
                  <h3 className="modeltitle">Plumbing</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      src={Tub2}
                      title="Tub"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          tub: !prev.tub,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={TubOverflow2}
                      title="Tub Overflow"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          tubOverflow: !prev.tubOverflow,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={Comode2}
                      title="Comode"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          comode: !prev.comode,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={ComodeSeat2}
                      title="Comode Seat"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          comodeSeat: !prev.comodeSeat,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={LavFaucet7}
                      title="Lav Faucet"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          lavFaucetDeckMount: !prev.lavFaucetDeckMount,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={LavFaucet8}
                      title="Lav Faucet"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          lavFaucet: !prev.lavFaucet,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={LavFaucet9}
                      title="Lav Faucet"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          lavFaucetWallMount: !prev.lavFaucetWallMount,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={LavDrain2}
                      title="Lav Drain"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          lavDrain: !prev.lavDrain,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={ShowerHeadwithTubSpoutTrimSet2}
                      title="Shower Head with Tub Spout, Trim Set"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          showerHeadWithTubSpoutTrimSet:
                            !prev.showerHeadWithTubSpoutTrimSet,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={ShowerOnlywithTrim2}
                      title="Shower Only with Trim"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          showerOnlyWithTrim: !prev.showerOnlyWithTrim,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={TransferTrim2}
                      title="Transfer Trim"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          transferTrim: !prev.transferTrim,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={HandShower2}
                      title="Hand Shower"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          handShower: !prev.handShower,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          tub: false,
                          tubOverflow: false,
                          comode: false,
                          comodeSeat: false,
                          lavFaucetDeckMount: false,
                          lavFaucet: false,
                          lavFaucetWallMount: false,
                          lavDrain: false,
                          showerHeadWithTubSpoutTrimSet: false,
                          showerOnlyWithTrim: false,
                          transferTrim: false,
                          handShower: false,
                        }));
                      }}
                    />
                  </div>

                  <h3 className="modeltitle">Lighting</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="WallPine"
                      src={VanitySconce2}
                      title="Vanity Sconce"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          vanitySconce: !prev.vanitySconce,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallPine"
                      src={VanityOverhead2}
                      title="Vanity Overhead"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          vanityOverhead: !prev.vanityOverhead,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallPine"
                      src={LightFixture2}
                      title="Light Fixture"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          lightFixture: !prev.lightFixture,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setUrbanCheckedOptions((prev) => ({
                          ...prev,
                          vanityOverhead: false,
                          vanitySconce: false,
                          lightFixture: false,
                        }));
                      }}
                    />
                  </div>
                </div>
              )}
              {selectedModel === "Vintage" && (
                <div>
                  {/* Paint Section */}
                  <h3 className="modeltitle">Paint</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="vanityLightOak"
                      src={img16}
                      title="Wall Paint"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          wallPaint: !prev.wallPaint,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="vanityWhiteWood"
                      src={CeilingPaintColor}
                      title="Ceiling Paint Color"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          ceilingPaintColor: !prev.ceilingPaintColor,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="floorNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          wallPaint: false,
                          ceilingPaintColor: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Accessories Section */}
                  <h5 className="modeltitle">Accessories</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      src={TPHolde3}
                      title="TP Holder"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          toiletPaperHolder: !prev.toiletPaperHolder,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={TowelBar3}
                      title="Towel Bar"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          towelBar: !prev.towelBar,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={TowelRing3}
                      title="Towel Ring"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          towelRing: !prev.towelRing,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={RobeHook3}
                      title="Robe Hook"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          robeHook: !prev.robeHook,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={mirror3}
                      title="Mirror"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          mirror: !prev.mirror,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={Medicinecabinet3}
                      title="Medicine Cabinet"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          medicineCabinet: !prev.medicineCabinet,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          toiletPaperHolder: false,
                          towelBar: false,
                          towelRing: false,
                          robeHook: false,
                          mirror: false,
                          medicineCabinet: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Tiles Section */}
                  <h3 className="modeltitle">Tiles</h3>

                  {/* Floor Tiles */}
                  <h5 className="subVanitytitle">Floor Tile</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="wall1"
                      src={MSIRagalloo3}
                      title="MSI - Ragallo"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          msiRagallo: !prev.msiRagallo,
                          msiEdenStatuary: false,
                          msiHexagono: false,
                          msiEdenStatuaryHex: false,
                          msi2InchBlackHex: false,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="wall1"
                      src={MSIEdenStatuaryy3}
                      title="MSI - Eden Statuary"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          msiEdenStatuary: !prev.msiEdenStatuary,
                          msiRagallo: false,
                          msiHexagono: false,
                          msiEdenStatuaryHex: false,
                          msi2InchBlackHex: false,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="wall1"
                      src={MSIHexagono3}
                      title="MSI - Hexagono"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          msiHexagono: !prev.msiHexagono,
                          msiRagallo: false,
                          msiEdenStatuary: false,
                          msiEdenStatuaryHex: false,
                          msi2InchBlackHex: false,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="wall1"
                      src={MSIEdenStatuaryhex3}
                      title="MSI - Eden Statuary hex"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          msiEdenStatuaryHex: !prev.msiEdenStatuaryHex,
                          msiRagallo: false,
                          msiEdenStatuary: false,
                          msiHexagono: false,
                          msi2InchBlackHex: false,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="wall1"
                      src={MSIinchblackhex3}
                      title="MSI - 2 inch black hex"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          msi2InchBlackHex: !prev.msi2InchBlackHex,
                          msiRagallo: false,
                          msiEdenStatuary: false,
                          msiHexagono: false,
                          msiEdenStatuaryHex: false,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="floorNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          msiRagallo: false,
                          msiEdenStatuary: false,
                          msiHexagono: false,
                          msiEdenStatuaryHex: false,
                          msi2InchBlackHex: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Wall Tiles */}
                  <h5 className="subVanitytitle">Wall Tile</h5>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="wall1"
                      src={MSIEdenStatuary3}
                      title="MSI-Eden Statuary"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          msiEdenStatuaryWall: !prev.msiEdenStatuaryWall,
                          msiKayaWall: false,
                          msiRagalloWall: false,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="wall1"
                      src={MSIKaya3}
                      title="MSI - Kaya"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          msiKayaWall: !prev.msiKayaWall,
                          msiEdenStatuaryWall: false,
                          msiRagalloWall: false,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="wall1"
                      src={MSIRagallo3}
                      title="MSI - Ragallo"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          msiRagalloWall: !prev.msiRagalloWall,
                          msiKayaWall: false,
                          msiEdenStatuaryWall: false,
                        }));
                      }}
                    />

                    <ColorCircle
                      id="floorNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          msiEdenStatuaryWall: false,
                          msiKayaWall: false,
                          msiRagalloWall: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Wall Accent Tiles */}
                  {/* <h5 className="subVanitytitle">Wall Accent Tile</h5>
    <div className="maincirlediv">
      <ColorCircle id="WallPine" src={TilebarPenny2} title="Tilebar - Penny" />
      <ColorCircle id="WallPine" src={EmserRaku} title="Emser - Raku matte" />
      <ColorCircle id="WallNone" src={img9} title="None" />
    </div> */}

                  {/* Vanity Section */}
                  <h3 className="modeltitle">Vanity</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="WallPine"
                      src={VanityALL3}
                      title="Vanity ALL"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          vanityAll: !prev.vanityAll,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={Cabinet}
                      title="None"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          vanityAll: false,
                        }));
                      }}
                    />
                  </div>

                  {/* Plumbing Section */}
                  <h3 className="modeltitle">Plumbing</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      src={Tub3}
                      title="Tub"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          tub: !prev.tub,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={TubOverflow3}
                      title="Tub Overflow"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          tubOverflow: !prev.tubOverflow,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={Comode3}
                      title="Comode"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          comode: !prev.comode,
                        }));
                        console.log(vintageCheckedOptions.comode);
                      }}
                    />
                    <ColorCircle
                      src={ComodeSeat3}
                      title="Comode Seat"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          comodeSeat: !prev.comodeSeat,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={LavFaucet10}
                      title="Lav Faucet"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          lavFaucet: !prev.lavFaucet,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={LavFaucet11}
                      title="Lav Faucet"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          lavFaucetDeckMount: !prev.lavFaucetDeckMount,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={LavDrain3}
                      title="Lav Drain"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          lavDrain: !prev.lavDrain,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={ShowerHead3}
                      title="Shower Head"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          showerHead: !prev.showerHead,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={ShowerArm3}
                      title="Shower Arm"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          showerArm: !prev.showerArm,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={Trim3}
                      title="Trim"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          trim: !prev.trim,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={TransferTrim3}
                      title="Transfer Trim"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          transferTrim: !prev.transferTrim,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={TubSpout3}
                      title="Tub Spout"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          tubSpout: !prev.tubSpout,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={HandShower3}
                      title="Hand Shower"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          handShower: !prev.handShower,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={ShowerBar3}
                      title="Shower Bar"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          showerBar: !prev.showerBar,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={Elbow3}
                      title="Elbow"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          elbow: !prev.elbow,
                        }));
                      }}
                    />
                    <ColorCircle
                      src={ShowerGlassDoor3}
                      title="Shower Glass Door"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          showerGlassDoor: !prev.showerGlassDoor,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          tub: false,
                          tubOverflow: false,
                          comode: false,
                          comodeSeat: false,
                          lavFaucet: false,
                          lavFaucetDeckMount: false,
                          lavDrain: false,
                          showerHead: false,
                          showerArm: false,
                          trim: false,
                          transferTrim: false,
                          tubSpout: false,
                          handShower: false,
                          showerBar: false,
                          elbow: false,
                          showerGlassDoor: false,
                        }));
                      }}
                    />
                  </div>

                  <h3 className="modeltitle">Lighting</h3>
                  <div className="maincirlediv">
                    <ColorCircle
                      id="WallPine"
                      src={VanitySconce3}
                      title="Vanity Sconce"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          vanitySconce: !prev.vanitySconce,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallPine"
                      src={LightFixture3}
                      title="Light Fixture"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          lightFixture: !prev.lightFixture,
                        }));
                      }}
                    />
                    <ColorCircle
                      id="WallNone"
                      src={img9}
                      title="None"
                      onClick={() => {
                        setVintageCheckedOptions((prev) => ({
                          ...prev,
                          vanitySconce: false,
                          lightFixture: false,
                        }));
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

export default App;
