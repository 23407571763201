export default function Lights() {
  return (
    <>
      {/* <ambientLight intensity={0.7} />
      <pointLight
        position={[-0.23, 2.2, 0.181]}
        intensity={0.5}
        castShadow
        receiveShadow
      />
      <pointLight
        position={[0.7, 2.05, -0.733]}
        intensity={0.5}
        castShadow
        receiveShadow
      />
      {/* <ambientLight intensity={0.75} castShadow receiveShadow/> */}
      {/* <directionalLight
        position={[0.2, 1.8, 2]}
        intensity={2}
        castShadow
        receiveShadow
      /> */}
      <ambientLight intensity={2} />
      {/* <pointLight position={[-0.23, 2.3, 0.181]} intensity={1} />
      <pointLight position={[0.7, 2.05, -0.733]} intensity={1} /> */}
      <directionalLight position={[0.2, 1.8, 2]} intensity={2} castShadow />
    </>
  );
}
