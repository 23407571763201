import { proxy } from 'valtio';
import inputData from './inputData';

// Create a proxy object to hold your state
const Store = proxy({
    loadingState: true,
    variant: "SPA",
    data: inputData,
    base_url: "/newModels3-7/",
    cameraControls: null,
});


export default Store;